import React, { useContext } from 'react';
import './headerrowfullscreen.css';
import { ReactComponent as CloseIcon } from "./baseline-clear-24px.svg";
import { Store } from "../../../Store";


function HeaderRowFullscreen(props){
    const { dispatch } = useContext(Store);

    const toggleFullscreen = () => {
        return dispatch({
            type: 'MEDIA_FULLSCREEN',
            payload: false
        });
    };
    return(
        <div>
            <div className="header-row-fullscreen-container">
                { props.haveText ?
                    <div
                        className="header-row-fullscreen-item info" onClick={props.toggleHandler} data-testid="info-tag">
                        <div className="info-item">i</div>
                    </div>
                    : null
                }
                <div onClick={toggleFullscreen} className="header-row-fullscreen-item" data-testid="close-tag">
                    <CloseIcon/>
                </div>
            </div>
        </div>
    );
}

export default HeaderRowFullscreen;