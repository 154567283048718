import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './timeline.module.css';
import HeaderRow from "../../Framework/HeaderRow/headerrow";
import Menu from "../../Framework/Menu/menu";
import TimelineSlider from "./TimelineSlider/timelineslider";
import { Store } from "../../../Store";
import history from "../../../history";
import {getPres, getTypeName} from "../../../common/functions";
import Image from "../../Framework/Image/image";

const Timeline = props => {
    const { state } = useContext(Store);
    const [ view, setView ] = useState({});
    const bgEl = useRef(null);
    const middleEl = useRef(null);

    const getPercentageScroll = (xPos, totalScroll, totalWidth) => {
       if (!(totalWidth && xPos && totalScroll)){
           return 0;
       }
       return (xPos / (totalScroll - totalWidth) * 100);
    };

    const getContentByLocale = (record, locale) => {
        try {
            return record.contents[locale];
        } catch (Error){
            return {};
        }
    };

    const getRecordImage = record => {
      try {
          return typeof record.image === 'string' ? record.image : record.image.urls[0].url;
      } catch (Error){
          return '';
      }
    };

    useEffect( () => {
        if ('presentations' in state.kiosk){
            let pres = getPres(state.kiosk.presentations, history.location.pathname);
            let loc = state.localeSettings.locale;
            if (pres){
                let _view = {
                    elements: pres.children,
                    type: pres.type,
                    title: pres.contents[loc].title || getTypeName(pres.type, loc),
                    desc: pres.contents[loc].description || ""

                };
                if ('resources' in pres && pres.resources.length > 0) {
                    pres.resources.map( x => {
                        if (x.type === 'timeline_interlayer'){
                            _view.bg_paralaxx = x.urls[0].url;
                        }
                        if (x.type === 'timeline_bg'){
                            _view.bg_normal = x.urls[0].url;
                        }
                        return x;
                    });
                }
                _view.elements.forEach( (item) => {
                    const { record_id } = item;
                    let record = state.kiosk.records.filter( x => x.id === record_id)[0];
                    item.record = record ? record : {};
                    item.content = getContentByLocale(record, loc);
                    item.image = getRecordImage(record);
                    item.title = item.content && 'title' in item.content ? item.content.title : '';
                    item.desc = item.content && 'description' in item.content ? item.content.description : '';
                });
                _view.elements = _view.elements.filter( x => 'contents' in x.record && loc in x.record.contents);
                setView(_view);
            }
        }
    }, [state.kiosk, state.localeSettings.locale]);

    useEffect( () => {
        const scrollEvent = () => {
            let pos = slider.scrollLeft;
            let total = slider.scrollWidth;
            let percentage = getPercentageScroll(pos, total, totalWidth);
            if (bgEl.current) bgEl.current.style.transform = `translateX(-${percentage / 2}%)`;
            if (middleEl.current) middleEl.current.style.transform = `translateX(-${percentage}%)`;
        }

        if (!('current' in bgEl && 'current' in middleEl)){
            return;
        }
        let slider = document.querySelector('.slider');
        let totalWidth = document.documentElement.clientWidth;
        slider.addEventListener('scroll', scrollEvent);
        return () => {
            slider.removeEventListener('scroll', scrollEvent);
        }

    }, [bgEl, middleEl]);


    return(
        <div className={styles.container}>
            <div className="page page-timeline">
                <div className={styles.header}>
                    <HeaderRow filter={true} title={view.title} toggleMenu={props.toggleMenu}/>
                </div>
               <div className={styles.parallax_container}>
                        { 'bg_paralaxx' in view ?
                            <div ref={middleEl} className={styles.bg_parallax + ' bg-parallax'}><Image sizes="200vw" src={view.bg_paralaxx} alt="Parallax"/></div>
                            : null }
                        { 'bg_normal' in view ?
                            <div ref={bgEl} className={styles.bg_normal + ' bg-normal'}><Image sizes="150vw" src={view.bg_normal} alt="Background"/></div>
                            : null}
               </div>
                    <TimelineSlider items={view.elements}/>
            </div>
            { props.isMenuOpen ? <Menu toggleMenu={props.toggleMenu}/> : null }
        </div>
    );
};

export default Timeline;