import React from 'react';
import styles from './timelineslider.module.css';
import Image from "../../../Framework/Image/image";
import {Link} from "react-router-dom";
import {getUrlPath} from "../../../../common/functions";

const TimelineSlider = props => {

    return(
        <div className={styles.container + ' slider'} data-testid="slider-tag">
            { props.items ? props.items.map( (item, i) => {
                const { record_id } = item;
                const haveImage = 'image' in item && item.image.length > 0;
                const itemStyles = haveImage ? styles.item : styles.item + ' ' + styles.item_missing_image;
                return(
                    <Link to={getUrlPath(item.type) + '/' + record_id.toString()} className={itemStyles} key={i}>
                            <Image lazy={false} src={item.image} sizes="25vw"/>
                            <h3>{item.title || 'aaa'}</h3>
                    </Link>
                );
            }) : null }
        </div>
    );
};

export default TimelineSlider;