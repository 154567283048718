import React, {useState} from "react"
import {getPosterUrl} from "../../../../../../common/functions";
import styles from "../block.module.css";
import Image from "../../../../../Framework/Image/image";
import Pagination from "../Pagination/pagination";

const BlockImageVideo = ({item, idx, block, loc, setCurrentGalleryFn}) =>{
    const [imageLoaded, setImageLoaded] = useState(false);
    const img_src = getPosterUrl(item.image || item.url[0].url);
    const overlayTitle = "Video tittel vil være her når det kommer";
    const overlayDesc = "Video beskrivelse vil være her når det kommer";
    const video = {title: overlayTitle, desc: overlayDesc}
    const contents = loc in item.contents ? item.contents[loc] : {};
    const caption = {'credits': item.credits}
    const { title, description } = contents;
    if (title && title.length > 0) caption.title = title;
    if (description && description.length > 0) caption.desc = description;
    return (<div style={{visibility:  imageLoaded ? "visible" : "hidden"}} id={idx} key={item.id} onClick={setCurrentGalleryFn} className={styles.item_image + ' ' + styles.item_origin_type_video}>
        <Image onLoad={()=>{setImageLoaded(true)}} playFilter={video} src={img_src} caption={caption} noMaxSrcSet={true} sizes="100vw" alt="Gallery item"/>
        { block.elements.length > 1 ? <Pagination pos={idx + 1} total={block.elements.length}/> : null }
    </div>);
}

export default BlockImageVideo;