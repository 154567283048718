import React from 'react';
import styles from './progressbar.module.css';


const ProgressBar = props => {

    return(
        <div onClick={props.changeProgress} className={styles.container}>
            <div className={styles.filler} style={{ width: `${props.percentage || "0"}%`
            }}/>
        </div>
    );
};

export default ProgressBar;