import React from 'react';
import styled from 'styled-components';
import Error from "../../Error/error";
import {getDevicePath} from "../../../common/functions";
import history from "../../../history";


const Container = styled.div`
color: white;

`;
const ErrorBoundaryContent = props => {

    history.listen( ( location ) => {
        window.location.reload();
    })

    const redirect = () => {
        window.location.href = '../#' + getDevicePath();
    }

    return(
       <Container>
           <Error
               text={'text' in props ? props.text : "Something went wrong"}
               action={redirect}/>
       </Container>
   )
};

export default ErrorBoundaryContent;