import React, { useReducer } from 'react'
import {DEFAULT_DEVICE_ID} from "./common/settings";

export const Store = React.createContext();

export const initialState = {
    id: localStorage.getItem('device_id') || DEFAULT_DEVICE_ID,
    kiosk: {},
    isMediaFullscreen: false,
    currentGallery: [],
    currentGalleryStartId: null,
    currentMediaId: null,
    themeId: 0,
    themeStyles: [
        {
            name: "Dark",
            cssName: "",
            bgColor: '#1C1A1A',
            textSecondaryColor: '#FFF',
            textColor: '#F6E6EA',
            supColor: "#A50C30"
        },
        {
            name: "Light",
            cssName: "theme-light",
            bgColor: "#E8E8E8",
            textSecondaryColor: '#CDCADD',
            textColor: "#8685EF",
            supColor: "#8685EF"
        },
    ],
    isFetching: false,
    localeSettings : {
        locale: "no",
        fallback: false,
        locales: []
    },
    override: {
      isActive: false,
      progress: null,
    },
    defaultPresentationId: null,
    inactivePaused: false
};

export function reducer(state, action) {
    switch (action.type) {
        case 'FETCH_DATA':
            return { ...state, kiosk: action.payload };
        case 'SET_ID':
            return { ...state, id: action.payload };
        case 'MEDIA_FULLSCREEN':
            return { ...state, isMediaFullscreen: action.payload };
        case 'CURRENT_GALLERY':
            return { ...state, currentGallery: action.payload };
        case 'CURRENT_GALLERY_START_ID':
            return { ...state, currentGalleryStartId: action.payload };
        case 'SET_THEME_ID':
            return { ...state, themeId: action.payload };
        case 'SET_IS_FETCHING':
            return { ...state, isFetching: action.payload };
        case 'SET_DEFAULT_PRESENTATION':
            return { ...state, defaultPresentationId: action.payload };
        case 'SET_CURRENT_MEDIA_ID':
            return { ...state, currentMediaId: action.payload };
        case 'SET_LOCALE_SETTINGS':
            return { ...state, localeSettings: action.payload };
        case 'SET_OVERRIDE':
            return { ...state, override: action.payload };
        case 'SET_INACTIVE_PAUSED':
            return { ...state, inactivePaused: action.payload };
        case 'RESET_STORE':
            return {...initialState, kiosk: state.kiosk, defaultPresentationId: state.defaultPresentationId};
        default:
            return state;
    }
}

export function StoreProvider(props) {
    const [ state, dispatch ] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    return <Store.Provider value={value}>{props.children}
    </Store.Provider>
}

