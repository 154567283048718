import React from 'react';
import styles from './pagination.module.css';

const Pagination = props => {
    return(
        <div className={styles.container}>{props.pos + " / " + props.total}</div>
    );

};

export default Pagination;