import React, { useState, useEffect, useContext } from 'react';
import { ReactComponent as BackIcon } from "./Path.svg";
import { ReactComponent as MenuIcon } from "./Group.svg";
import history from "../../../history";
import './headerrow.css';
import { Store } from '../../../Store';
import LanguageIcon from "../../Views/Main/LanguageIcon/languageicon";
import styled from 'styled-components';
import LanguageOverlay from "../../Views/Main/LanguageOverlay/languageoverlay";

const LanguageContainer = styled.div`
    z-index: 3;
    padding: 0 25px;
    transform: scale(0.7) translateY(13px);
    svg{
      filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, .5));
    }
`;

function HeaderRow({languageSwitcher, ...props}) {
        const { state } = useContext(Store);
        const [ isBackActive, setIsBackActive ] = useState(false);
        const [ languageOpen, setLanguageOpen ] = useState(false);
        const svgSizes = {
            height: "40px",
            width: "40px"
        };

        const styles = {
            container: {
               fixed:  {
                   position: 'fixed',
                   left: 'var(--standard-hmargin)',
                   right: 'var(--standard-hmargin)',
                   zIndex: '5',
                   touchAction: 'none'
               }
            },
            header: {
                noBack: {
                    paddingLeft: '0'
                }
            }
        }

        useEffect( () => {
            if (state.defaultPresentationId > 0){
                let [,,,,pathId] = history.location.pathname.split('/');
                let id = pathId ? parseInt(pathId) : false;
                if (state.defaultPresentationId === id){
                    setIsBackActive(false);
                } else {
                    setIsBackActive(true);
                }
            } else {
                setIsBackActive(true);
            }
        }, [state]);

        return(
            <React.Fragment>
                <div className="article-header-row" style={props.fixed ? styles.container.fixed : {}}>
                    { isBackActive ?
                        <BackIcon onClick={history.goBack} width={svgSizes.width} height={svgSizes.height} className="article-button-back" styles={ isBackActive ? {} : {display: 'none'}}/>
                        : null}

                    <h1 className="article-header" style={isBackActive ? {} : styles.header.noBack} data-testid="title-tag">{props.title}</h1>
                    <>
                        {
                            languageSwitcher && state.localeSettings.locales.length > 1 ?
                                <LanguageContainer onClick={() => {setLanguageOpen(!languageOpen)}}>
                                    <LanguageIcon/>
                                </LanguageContainer>
                                : null
                        }
                        { 'menuDisabled' in props && props.menuDisabled ? null :
                            <MenuIcon onClick={props.toggleMenu} width={svgSizes.width} height={svgSizes.height} data-testid="menu-icon" className="article-menu"/>
                        }
                    { 'filter' in props && props.filter ? <div className={'article-filter'}/> : null }
                    </>
                </div>
               { /* 'byline' in props ?  <Byline/> : null */ }
                {languageOpen ? <LanguageOverlay changeLang={() => setLanguageOpen(!languageOpen)}/> : null }
            </React.Fragment>
        );
}

export default HeaderRow;