import React from 'react';
import { ReactComponent as Icon } from './baseline-language-24px.svg';
import './languageicon.css';

class LanguageIcon extends React.Component {

    render(){
        return (
            <Icon className="language-icon" style={{fill: 'var(--theme-textColor)'}}/>
        );
    }
}

export default LanguageIcon;