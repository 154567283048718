import {getEnvironment} from "./functions";

export const DEFAULT_DEVICE_ID = (getEnvironment() === 'local') ? 318  : 326;
export const DEFAULT_SCREEN_TIMEOUT = (getEnvironment() === 'local') ? 1000*60*60 : 60*1000;
// MAP Settings
export const MAX_MAP_ZOOM = 4;
// API Settings
export const API_PATH = "https://api.kulturpunkt.org";
export const API_RESTART_TIMER = 30;
export const API_MAX_RESTARTS = 5;
export const API_KEY = '';
// OVERRIDE Settings
export const BAUD_RATE = 5;
export const CHECK_TIMER = 10;
// AUTOPLAY TIMERS IN MS
export const AUTOPLAY_IMAGE_TIMER = 10000;