import React, {useState} from "react";
import styles from "../block.module.css";
import Image from "../../../../../Framework/Image/image";
import Pagination from "../Pagination/pagination";


const BlockImage = ({item, block, idx, loc, setCurrentGalleryFn}) =>{
    const [imageLoaded, setImageLoaded] = useState(false);
    const contents = loc in item.contents ? item.contents[loc] : {};
    const { title, description } = contents;
    const { credits } = item;
    const caption = {};
    if (title && title.length > 0) caption.title = title;
    if (description && description.length > 0) caption.desc = description;
    if (credits) caption.credits = credits;
    return (<div style={{visibility:  imageLoaded ? "visible" : "hidden"}} id={idx} key={item.id} onClick={setCurrentGalleryFn} className={styles.item_image}>
        <Image onLoad={()=>{setImageLoaded(true)}} src={item.image || item.url[0].url} alt="Gallery item" sizes="100vw" noMaxSrcSet={true} caption={caption ? caption : false}/>
        { block.elements.length > 1 && imageLoaded ? <Pagination pos={idx + 1} total={block.elements.length}/> : null}
    </div>);
}

export default BlockImage;