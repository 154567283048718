import React, { useState, useEffect, useContext, useRef } from 'react';
import Main from './components/Views/Main/main.js';
import List from './components/Views/List/list.js';
import Article from './components/Views/Article/article.js';
import ImageView from './components/Views/ImageView/imageview.js';
import Mosaic from "./components/Views/Mosaic/mosaic";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Store } from "./Store.js";
import history from './history';
import './App.css';
import IdleTimer from "react-idle-timer";
import SlideShow from "./components/Views/SlideShow/slideshow";
import VideoGallery from "./components/Views/VideoGallery/videogallery";
import PhotoGallery from "./components/Views/PhotoGallery/photogallery";
import Timeline from "./components/Views/Timeline/timeline";
import ImageMap from "./components/Views/ImageMap/imagemap.js";
import Error from "./components/Error/error";
import {
    DEFAULT_SCREEN_TIMEOUT,
    API_PATH,
    BAUD_RATE,
    CHECK_TIMER,
    API_MAX_RESTARTS
} from "./common/settings";
import ErrorBoundary from "./errorboundary";
import {getDevicePath, getHashParams} from "./common/functions";

const App = props => {
    const { state, dispatch } = useContext(Store);
    const [ isMenuOpen, setIsMenuOpen ] = useState(false);
    const [ themeName, setThemeName ] = useState("");
    const [ inactive, setInactive ] = useState(false);
    const [ deviceNotFound, setDeviceNotFound ] = useState(false);
    const [ restartStopped, setRestartStopped ] = useState(false);
    const [ isReadyForFetch, setIsReadyForFetch ] = useState(false);
    const [ locales, setLocales ] = useState([]);

    const idleTimer = useRef(null);

    //document.oncontextmenu = new Function("return false");
    document.oncontextmenu = function(){
        return false;
    };


    const toggleMenu = e => {
        const MenuItemClicked = e.target.classList.contains('menu-item-text');
        if (isMenuOpen){
            document.querySelector('.menu-filter').classList.add('fastFadeOut');
            document.querySelector('.menu-content').classList.add('slideOut');
            if (MenuItemClicked){
                setIsMenuOpen(!isMenuOpen);
                return;
            }
            let timeout = setTimeout( () => {
                setIsMenuOpen(!isMenuOpen);
            }, 300);
            return () => {
                clearTimeout(timeout);
            }
        } else {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    const resetStore = () => {
        dispatch({
            type: 'RESET_STORE',
            payload: null
        })
    };

    const deviceNotFoundHandler = () => {
        let restart = sessionStorage.getItem('restart');
        restart = JSON.parse(restart);
        if (restart && 'id' in restart && restart.id === state.id){
            if (restart.attempts >= API_MAX_RESTARTS){
                setRestartStopped(true);
                return;
            }
            let res = { id: state.id, attempts: restart.attempts + 1};
            sessionStorage.setItem('restart', JSON.stringify(res));
        } else {
            let res = { id: state.id, attempts: 1};
            sessionStorage.setItem('restart', JSON.stringify(res));
        }
        let id = localStorage.getItem('device_id');
        history.push('/device/' + id);
    };

    const onActive = e => {
        if ('current' in idleTimer) {
            setInactive(false);
            console.log('user is active', e);
        }
    };
    const onAction = e => {
        if ('current' in idleTimer){
            console.log('user did something', e);
        }
    };
    const onIdle = e => {
        if ('current' in idleTimer){
            console.log('user is idle', e);
        }
        setIsMenuOpen(false);
        resetStore();
        setInactive(true);
    };
    const convertHttpToHttps = arr => {
        arr.forEach( item => {
            if ('url' in item){
                item.url = item.url.replace(/^http:\/\//i, 'https://');
            }
            if ('url2' in item){
                item.url2 = item.url2.replace(/^http:\/\//i, 'https://');
            }
        });
    };


    useEffect( () => {

        const fetchData = async (id, fetchSignal) => {
            dispatch({
                type: 'SET_IS_FETCHING',
                payload: true
            });
            const _url = !!window.wrapUrl ? window.wrapUrl(API_PATH + "/kiosk/device/" + id) : API_PATH + "/kiosk/device/" + id;
            return await fetch(_url, { signal: fetchSignal })
                .then(response => {
                    if (!response.ok) {
                        setDeviceNotFound(true);
                        dispatch({
                            type: 'SET_IS_FETCHING',
                            payload: false
                        });
                        throw new Error("Network response not ok");
                    } else if (response.status === 404){
                        setDeviceNotFound(true);
                    } else if (response.status === 200){
                        setDeviceNotFound(false);
                    }
                    return response.json();
                })
                .then(data => {
                    dispatch({
                        type: 'SET_IS_FETCHING',
                        payload: false
                    });
                    const { device: [ { default_presentation: defaultPres } ] } = data;
                    if ( typeof defaultPres === 'number'){
                        dispatch({
                            type: 'SET_DEFAULT_PRESENTATION',
                            payload: defaultPres
                        });
                    }
                    // Parse available locales
                    let newLocales = [];
                    data.records.forEach( record => {
                        Object.entries(record.contents).forEach( (loc, i) => {
                            if(newLocales.indexOf(loc[0]) < 0){
                                newLocales.push(loc[0]);
                            }
                        });
                    });
                    setLocales(newLocales);
                    // Sort presentations by order from device settings - if exist
                    let { presentations: presSorts } = ('device' in data) ? data.device[0] : [];
                    if (presSorts && Array.isArray(presSorts)){
                        data.presentations.map( x => {
                            let presWithPos = presSorts.find( y => y.id === x.id);
                            x.position = (typeof presWithPos === 'undefined') ? 100 : presWithPos.position;
                            return x;

                        });
                        data.presentations.sort( (a, b) => (a.position > b.position) ? 1 : -1);
                    }
                    // convert resource urls http to https
                    data.presentations.forEach( pres => {
                        if ('resources' in pres &&
                            Array.isArray(pres.resources) &&
                            pres.resources.length > 0){
                            pres.resources.forEach( res => {
                                if ('urls' in res &&
                                    Array.isArray(res.urls) &&
                                    res.urls.length > 0) {
                                    convertHttpToHttps(res.urls);
                                }
                            });
                        }
                    });
                    return data;
                })
                .catch(err => {
                    setDeviceNotFound(true);
                    dispatch({
                        type: 'SET_IS_FETCHING',
                        payload: false
                    });
                    console.log("Fetch error: " + err);
                });
        };

        // EXECUTION STARTS

        if (!isReadyForFetch){
            return;
        }

        const abortController = new AbortController();
        const signal = abortController.signal;

        dispatch({
            type: 'SET_DEFAULT_PRESENTATION',
            payload: null
        });

        fetchData(props.id, signal)
            .then( data => {
                if (data && 'device' in data){
                    dispatch({
                        type: 'FETCH_DATA',
                        payload: data
                    });
                }
            })
            .catch ( err => {
                console.log(err);
            });

        return function cleanup(){
            abortController.abort();
        };
    }, [props.id, isReadyForFetch, dispatch]);

    useEffect( () => {
        let name = state.themeStyles[state.themeId].cssName;
        setThemeName(name);
    }, [state.themeId, state.themeStyles]);


    useEffect ( () => {
        if (idleTimer !== null){
            idleTimer.current.reset();
        }
    }, [state.inactivePaused]);

    useEffect( () => {
        if (state.localeSettings.locales !== locales){
            dispatch({
                type: 'SET_LOCALE_SETTINGS',
                payload: {
                    ...state.localeSettings,
                    locales: locales
                }
            });
        }
    }, [locales, state.localeSettings, dispatch]);


    useEffect( () => {

        const updateOverride = (isActive, progress) => {
            dispatch({
                type: 'SET_OVERRIDE',
                payload: { ...prevState => { return prevState.override; }, isActive, progress}
            });
        };
        const params = getHashParams(history.location.search);
        const needWait = params && 'waitfor' in params && params.waitfor === 'wrapUrl';
        const baudRate = params && 'baudrate' in params ? params.baudrate : BAUD_RATE;
        const checkTimer = params && 'checktimer' in params ? params.checktimer : CHECK_TIMER;
        const wrapUrlChanged = () => {
            return window.wrapUrl('test') !== 'test';
        };

        if (!isReadyForFetch && needWait && !wrapUrlChanged()){
            let counter = 0;
            let interval = setInterval( () => {
                updateOverride(true, counter * 100 / (baudRate * checkTimer));
                if (wrapUrlChanged()){
                    setIsReadyForFetch(true);
                    clearInterval(interval);
                }
                if (counter >= (baudRate * checkTimer)){
                    clearInterval(interval);
                    updateOverride(false, null);
                }
                counter++;
            }, 1000 / baudRate);
            return () => {
                clearInterval(interval);
                updateOverride(false, null);
            };
        } else {
            setIsReadyForFetch(true);
            updateOverride(false, null);
        }
    }, [isReadyForFetch, dispatch]);

    /* for test/simluatiing overrides of window.wrapUrl
    useEffect( () => {
        let timeout = setTimeout( () => {
            window.wrapUrl = function (url){
                return url + "?herharviendranoe";
            };
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, [])
    */

  return (
          <Router history={history}>
              <IdleTimer
              ref={idleTimer}
              element={document}
              onActive={onActive}
              onIdle={onIdle}
              onAction={onAction}
              debounce={1}
              timeout={DEFAULT_SCREEN_TIMEOUT}/>
              { inactive ? <Redirect to={getDevicePath()}/> : null}
                  <div className={"App " + themeName} data-testid="app-tag">
                      { deviceNotFound ?
                          restartStopped ?
                              <Error text={"Could not find device. Make sure the device id is correct. DEVICE ID: " + state.id}/> :
                              <Error text="Device not found" action={deviceNotFoundHandler}/> :
                          <Switch>
                              <ErrorBoundary>
                                  <Route
                                      path="/device/:id"
                                      exact
                                      render={() => <Main/>}
                                  />
                                  <Route
                                      path="/device/:id/list/:id"
                                      render={() => <List toggleMenu={toggleMenu} isMenuOpen={isMenuOpen}/>}
                                  />
                                  <Route
                                      path="/device/:id/article/:id" exact
                                      render={() => <Article toggleMenu={toggleMenu} isMenuOpen={isMenuOpen}/>}
                                  />
                                  <Route
                                      path="/device/:id/image/:id"
                                      render={() => <ImageView/>}
                                  />
                                  <Route
                                      path="/device/:id/mosaic/:id"
                                      render={() => <Mosaic toggleMenu={toggleMenu} isMenuOpen={isMenuOpen}/>}
                                  />
                                  <Route
                                      path="/device/:id/videogallery/:id"
                                      render={() => <VideoGallery toggleMenu={toggleMenu} isMenuOpen={isMenuOpen}/>}
                                  />
                                  <Route
                                      path="/device/:id/photogallery/:id"
                                      render={() => <PhotoGallery toggleMenu={toggleMenu} isMenuOpen={isMenuOpen}/>}
                                  />
                                  <Route
                                      path="/device/:id/timeline/:id"
                                      render={() => <Timeline toggleMenu={toggleMenu} isMenuOpen={isMenuOpen}/>}
                                  />
                                  <Route
                                      path="/device/:id/imagemap/:id"
                                      render={() => <ImageMap toggleMenu={toggleMenu} isMenuOpen={isMenuOpen}/>}
                                  />
                                  <Route
                                      path="/device/:id/slideshow/:id"
                                      render={() => <SlideShow toggleMenu={toggleMenu} isMenuOpen={isMenuOpen}/>}
                                  />
                              </ErrorBoundary>
                          </Switch>
                      }
                  </div>
          </Router>
  );
}

export default App;
