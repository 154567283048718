import React from 'react';
import styles from './button.module.css';

const Button = props => {

    return(<div onClick={props.action} className={styles.container}>
        {props.text}
    </div>);
};

export default Button;