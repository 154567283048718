import React, {useState, useEffect} from 'react';
import history from '../../../history';
import App from "../../../App";

const Device = () => {
    const [id, setId] = useState(null);

    useEffect(() => {
        let path = history.location.pathname;
        let pathAr = path.split('/');
        try {
            if (pathAr[1] !== 'device' || typeof parseInt(pathAr[2]) !== 'number') {
                throw Error("Could not parse id from url");
            }
            let _id = parseInt(pathAr[2]);
            setId(_id);
        } catch (err) {
            console.log("Could not parse deviceid: Err:", err);
        }
    }, []);

    useEffect(() => {
        history.listen(() => {
            try {
                const pathAr = history.location.pathname.split('/');
                pathAr.forEach((item, i) => {
                    if (item === 'device' && pathAr.length - 1 >= i) {
                        let _id = parseInt(pathAr[i + 1]);
                        setId(_id);

                    }
                });
            } catch (err) {
                console.warn("Could not get a new device id from url");
            }
        });
    }, []);


    return (
        <div>
            {id ?
                <App id={id}/>
                : <div style={{color: 'white'}}>Need to set device id</div>}
        </div>
    );
};

export default Device;