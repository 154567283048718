import React, {useState, useEffect, useContext, useRef} from 'react';
import HeaderRow from "../../Framework/HeaderRow/headerrow";
import Menu from "../../Framework/Menu/menu";
import history from "../../../history";
import {Store} from "../../../Store.js";
import './article.css';
import styles from './article.module.css';
import Page from "./Page/page.js";
import ImageView from "../ImageView/imageview";
import PageNav from "./PageNav/pagenav";
import Image from "../../Framework/Image/image";
import Divider from "../../Divider/divider";
import {Redirect} from "react-router-dom";
import {getDevicePath} from "../../../common/functions";

const extractTitle = (article, state) => {
    try {
        return article.contents[state.localeSettings.locale].title
    } catch (err){
        return '';
    }
}

const Article = props => {
    const {state} = useContext(Store);
    const [article, setArticle] = useState({});
    const [content, setContent] = useState({});
    const [contentNotFound, setContentNotFound] = useState(false);
    const [headerHeight, setHeaderHeight] = useState({minHeight: "calc(440rem / 29)", height: "calc(440rem/29("});
    const pageEl = useRef(null);

    const getContentForLocale = (cont, locale) => {
        try {
            const _content = cont[locale];
            return _content ? _content : false;
        } catch (err) {
            return false;
        }
    };

    const changePageHandler = idx => {

        let art = article;
        let _content = getContentForLocale(art.contents, state.localeSettings.locale);
        if (!_content) {
            setContent({});
            setContentNotFound(true);
            return;
        }
        let page = _content.pages[idx - 1];
        console.log(idx, page);
        page = filterVimeo(page);
        setContent({...page});
        art.pages.forEach((p, i) => {
            p.selected = i === idx - 1;
        });
        setArticle({...art});
    };

    const filterVimeo = arr => {
        arr.blocks = arr.blocks.filter(block => {
            if (block.type !== 'image_video') {
                return block;
            } else {
                if (Array.isArray(block.data)) {
                    block.data = block.data.filter(ele => ele.type !== 'vimeo');
                }
                return block;
            }
        });
        return arr;
    };

    const isImageSet = () => {
        try {
            if (article.image.urls[0].url.length > 3) {
                return true;
            }
        } catch (err) {
            return false;
        }
    };


    useEffect(() => {

        const getArticleId = () => {
            let pathAr = window.location.hash.split('/');
            let id = null;
            pathAr.forEach((item, i) => {
                if (item === 'article' && pathAr.length - 1 >= i) {
                    id = parseInt(pathAr[i + 1]);
                }
            });
            return id;
        };

        if ('records' in state.kiosk && history.location.pathname) {
            'scrollIntoView' in pageEl.current && pageEl.current.scrollIntoView();
            let articleId = getArticleId();
            if (articleId) {
                let art = state.kiosk.records.find(item => item.id === articleId);
                if (typeof art === 'undefined') {
                    setArticle({});
                    setContentNotFound(true);
                    return;
                }
                let _content = getContentForLocale(art.contents, state.localeSettings.locale);
                if (!_content) {
                    setContent({});
                    setContentNotFound(true);
                    return;
                }
                let pages = [];
                _content.pages.forEach(item => {
                    pages.push({
                        position: item.position,
                        selected: item.position === 1,
                        title: item.title || ''
                    });
                });
                art.pages = pages ? pages : [];
                let page = _content.pages[0];
                page = filterVimeo(page);
                setContent({...page});
                setArticle({...art});
            }
        }
    }, [state.kiosk, state.localeSettings.locale]);

    useEffect(() => {
        try {
            if (article.image.urls[0].url.length > 3)
                setHeaderHeight({minHeight: "calc(650rem / 29)", height: "calc(650rem / 29)"});
        } catch (err) {
            setHeaderHeight({minHeight: "calc(270rem / 29)", height: "calc(270rem / 29)"});
        }
    }, [article]);

    return (
        <div ref={pageEl} className="page page-article">
            <div className="article-header-container">
                <HeaderRow className="article-header-row"
                           title={extractTitle(article, state)}
                           toggleMenu={props.toggleMenu} byline/>
            </div>
            <div className="article-img" style={headerHeight}>
                {article.image ?
                    <Image alt={article.image.name} src={article.image.urls[0].url}/>
                    : null}
            </div>

            {'pages' in article && article.pages.length > 1 ?
                <div className="article-pageNav">
                    <PageNav action={changePageHandler} items={article.pages}/>
                </div>
                : null}

            {contentNotFound ? <div className={styles.no_content_container}><Redirect to={getDevicePath()}/></div> :
                <Page page={content} isImageSet={isImageSet()}/>
            }

            {props.isMenuOpen ? <Menu toggleMenu={props.toggleMenu}/> : null}
            {'isMediaFullscreen' in state &&
            state.isMediaFullscreen &&
            state.currentGallery.length > 0 &&
            typeof state.currentGalleryStartId === 'number' ?
                <ImageView startIndex={state.currentGalleryStartId} items={state.currentGallery}/> : null}

            {'pages' in article && article.pages.length > 1 ?
                <div className="article-pageNav">
                    <PageNav action={changePageHandler} items={article.pages}/>
                </div>
                : null}

        </div>
    );

};

export default Article;