import React, {useContext, useEffect, useState} from 'react';
import {Router, NavLink} from 'react-router-dom';
import history from "../../../history";
import {Store} from '../../../Store.js';
import {version} from '../../../../package.json';
import {ReactComponent as HomeIcon} from './baseline-home-24px.svg';
import {ReactComponent as TimelineIcon} from '../../Views/Main/SliderMenuItem/timeline.svg';
import {ReactComponent as ImageGalleryIcon} from '../../Views/Main/SliderMenuItem/image_gallery.svg';
import {ReactComponent as MosaicIcon} from '../../Views/Main/SliderMenuItem/mosaic.svg';
import {ReactComponent as SingleIcon} from '../../Views/Main/SliderMenuItem/single.svg';
import './menu.css';
import {ReactComponent as VideoGalleryIcon} from "../../Views/Main/SliderMenuItem/video_gallery.svg";
import {ReactComponent as ListIcon} from "../../Views/Main/SliderMenuItem/list.svg";
import {ReactComponent as MapIcon} from "../../Views/Main/SliderMenuItem/map.svg";
import {getTypeName, getUrlPath} from "../../../common/functions";

const Menu = (props) => {
    const { state, dispatch } = useContext(Store);
    const [ items, setItems ] = useState([]);

    const style = {
        chosenLang: {
            color: "var(--theme-detailColor)"
        },
        currentPage: {
            color: "var(--theme-detailColor)",
            svg: {
                fill: "var(--theme-detailColor)"
            }
        }
    };

    const parseId = item => {
        try{
            if (item.type !== 'single'){
                throw Error("not single");
            }
            let id = item.children[0].record_id;
            return id;
        } catch (err){
            return item.id;
        }
    };

    const getLanguageFromAbbrv = abbrv => {
        const languages = {
            no: 'Norsk',
            es: 'Española',
            en: 'English',
            fr: 'Francais',
            sv: 'Svenska',
            de: 'Deutche'
        };
        return abbrv in languages ? languages[abbrv] : 'en';
    };

    const getMenuIcon  = (type, sel = false) => {
        switch (type) {
            case 'single':
                return <SingleIcon style={sel ? style.currentPage.svg : {}} />;
            case 'image_gallery':
                return <ImageGalleryIcon style={sel ? style.currentPage.svg : {}}/>;
            case 'video_gallery':
                return <VideoGalleryIcon style={sel ? style.currentPage.svg : {}}/>;
            case 'mosaic':
                return <MosaicIcon style={sel ? style.currentPage.svg : {}}/>;
            case 'index':
                return <ListIcon style={sel ? style.currentPage.svg : {}} />;
            case 'map_image':
                return <MapIcon style={sel ? style.currentPage.svg : {}} />;
            case 'timeline':
                return <TimelineIcon style={sel ? style.currentPage.svg : {}}/>;
            default:
                return <SingleIcon style={sel ? style.currentPage.svg : {}} />;
        }
    };
    const initItems = () => {
        let menuItems = (Array.isArray(state.kiosk.presentations) && state.kiosk.presentations.length > 0) ? state.kiosk.presentations : false;
        setItems(menuItems);
    };

    const menuStyles = {
        backgroundColor: "var(--theme-bgColor)"
    };

    const setLocale = (locale) => {
        const { localeSettings: settings } = state;
        settings.locale = locale;
        dispatch({
            type: 'SET_LOCALE_SETTINGS',
            payload: settings
        });
    };

    useEffect( () => {
        if ('presentations' in state.kiosk){
            initItems();
        }
    });

    return (
        <div className="menu" data-testid="menu-tag">
            <div className="menu-filter" onClick={props.toggleMenu} data-testid="filter-tag"/>
            <div className="menu-content" style={menuStyles}>
                <div className="menu-items" data-testid="menu-items-tag">
                    <Router history={history}>
                    <NavLink onClick={props.toggleMenu} className="menu-item" to={getUrlPath('home')} exact activeClassName='menu-item-selected'>
                        <HomeIcon/><div className="menu-item-text">{getTypeName('home', state.localeSettings.locale)}</div>
                    </NavLink>
                    { items.map( (item, i) => {
                        let id = parseId(item);
                        return(
                            <NavLink onClick={props.toggleMenu} className="menu-item" key={i} activeClassName='menu-item-selected' to={getUrlPath(item.type) + '/' + id}>
                                {getMenuIcon(item.type)}<div className="menu-item-text">{item.contents[state.localeSettings.locale].title || getTypeName(item.type, state.localeSettings.locale)}</div>
                            </NavLink>
                        );
                    })}
                    </Router>
                </div>
                    <div className="menu-footer">
                        <div className="menu-footer-lang">
                            { state.localeSettings.locales.length > 1 ? state.localeSettings.locales.map( item  => {
                                const language = getLanguageFromAbbrv(item);
                                if (item === state.localeSettings.locale){
                                    return(<div key={item} className="menu-footer-lang-item" style={style.chosenLang}>{language}</div>);
                                } else {
                                    return(<div key={item} onClick={ () => setLocale(item)} className="menu-footer-lang-item">{language}</div>);
                                }
                            }): null}
                        </div>
                        <div className="menu-footer-brand">
                            <h5>Powered by Kulturio Kiosk</h5>
                            <h6>v{version}</h6>
                        </div>
                </div>
            </div>

        </div>
    );
};

export default Menu;