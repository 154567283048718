import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import Menu from "../../Framework/Menu/menu";
import {Store} from "../../../Store.js";
import styles from './mosaic.module.css';
import HeaderRow from "../../Framework/HeaderRow/headerrow";
import Image from "../../Framework/Image/image.js";
import history from "../../../history";
import {getPres, getPresLink} from "../../../common/functions";

const Mosaic = props => {
    const {state} = useContext(Store);
    const [presentation, setPresentation] = useState({});
    const [view, setView] = useState({});
    const [items, setItems] = useState([]);

    useEffect(() => {

        const getStyles = ({alignment = false, color = false, placement = false, font = false, ...rest}) => {
            let item = {};
            item.color = (color && color !== '#000000') ? color : `var(--theme-textColor2)`;
            if (alignment) {
                item.textAlign = alignment;
            }
            if ('font-size' in rest) {
                let fontSize = rest['font-size'];
                // item.fontSize = fontSize.includes('large') ? "30px" : "15px";
                item.fontSize = fontSize.includes('large') ? "calc(30rem / 29)" : "calc(15rem / 29)";
            } else {
                // item.fontSize = (rest.position === 1) ? "16px" : "30px";
                item.fontSize = (rest.position === 1) ? "calc(16rem / 29)" : "calc(30rem / 29)";
            }
            item.fontWeight = "400";
            item.lineHeight = "1em";
            return item;
        };

        const getTextStyles = ({placement = false}) => {
            let item = {};
            if (placement) {
                item.left = placement.includes('left') ? "0" : "unset";
                item.top = placement.includes('top') ? "0" : "unset";
                item.bottom = placement.includes('bottom') ? "0" : "unset";
                item.right = placement.includes('right') ? "0" : "unset";
            }
            return item;
        };
        const getLink = item => {
            console.log(window.location.hash);
            console.log(window.location.hash.split('/').splice(1, 3).join('/'));
            let prefix = window.location.hash.split('/');
            if (item.type === 'presentation') {
                return getPresLink(item['presentation_id'], presentation.siblings || []);
            } else {
                return '/' + prefix[1] + '/' + prefix[2] + '/article/' + item['record_id'];
            }
        };

        const getDefaultTextStyles = () => {
            return {
                top: "unset",
                left: "0",
                bottom: "0",
                right: "unset"
            };
        };
        const saturateTextData = (item, content) => {
            item.titleObj = 'title' in content ? content.title : {};
            item.descObj = 'description' in content ? content.description : {};
            item.title = 'content' in item.titleObj ? item.titleObj.content : '';
            item.desc = 'content' in item.descObj ? item.descObj.content : '';
            item.link = getLink(item);
            item.titleStyles = getStyles(item.titleObj ? item.titleObj : {});
            item.descStyles = getStyles(item.descObj ? item.descObj : {});
            item.textStyles = getTextStyles(item.titleObj ? item.titleObj : getDefaultTextStyles());
            item.imageStyles = {
                objectPosition: "0 0",
                borderRadius: "3px"
            };
            item.isTop = item.textStyles.top === '0';
        };

        if (Object.entries(presentation).length > 0) {
            let pres = presentation;
            let loc = state.localeSettings.locale;
            let presItems = ('children' in pres) ? pres.children : [];
            let elementSizes = ('template' in pres &&
                'elementSizes' in pres.template &&
                Array.isArray(pres.template.elementSizes) &&
                pres.template.elementSizes.length > 0) ? pres.template.elementSizes : false;
            presItems.forEach((item, i) => {
                // Check if record of item have available language support - true / false
                let record = pres.records.find(x => x.id === item['record_id']);
                item.active = item.type === 'record' ? record && loc in record['contents'] : true;

                let c = (elementSizes[i] && 'x' in elementSizes[i]) ? elementSizes[i].x : 1;
                let r = (elementSizes[i] && 'y' in elementSizes[i]) ? elementSizes[i].y : 1;
                item.styles = {
                    gridRow: 'span ' + r,
                    gridColumn: 'span ' + c
                };
                item.rows = r;
                item.columns = c;
                item.sizes = c / presentation.template.width * 100 + 'vw';
                saturateTextData(item, 'text' in item && loc in item.text ? item.text[loc] : {});
            });
            setItems(presItems);

            let _view = {};
            let {template} = presentation;
            _view.rows = parseInt(template.height, 10);
            _view.columns = parseInt(template.width, 10);
            _view.title = presentation.contents[loc].title || "";
            if (_view.rows && _view.columns) {
                _view.styles = {
                    gridTemplateRows: 'repeat(' + _view.rows.toString() + ', calc(calc(100vh - ' + (280 + (_view.rows - 1) * 15) + 'px)/' + _view.rows.toString() + '))',
                    gridTemplateColumns: 'repeat(' + _view.columns.toString() + ', 1fr)'
                };
            }
            const {resources} = presentation;
            const [resource] = resources;
            if (resource) {
                const {type} = resource;
                if (type && type === 'timeline_bg') _view.pageStyles = {
                    background: `url("${resource.urls[0].url}")`,
                    backgroundPosition: "50% 50%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                };
            }
            setView(_view);

        }
    }, [presentation, state.localeSettings.locale]);

    useEffect(() => {
        if ('presentations' in state.kiosk) {
            let pres = getPres(state.kiosk.presentations, history.location.pathname);
            pres.records = 'records' in state.kiosk ? state.kiosk.records : [];
            pres.siblings = state.kiosk.presentations;
            setPresentation(pres);
        }
    }, [state.kiosk]);


    return (
        <div className="page mosaic" style={view.pageStyles}>
            <HeaderRow filter={true} className="article-header-row" title={view.title} toggleMenu={props.toggleMenu}/>
            <div className={styles.container + ' mosaic'} style={view.styles}>
                {items.map((item, i) => {
                    return (
                        <Link to={item.active ? item.link : null} key={i}
                              className={styles.item + ` ${item.image ? item.active ? item.isTop ? 'filter-to-top' : '' : 'disabled' : 'filter-disabled'}`}
                              style={item.styles}>
                            <Image textStyles={item.textStyles} sizes={item.sizes} descStyles={item.descStyles}
                                   desc={item.desc || ""} title={item.title} src={item.image}
                                   imageStyles={item.imageStyles} titleStyles={item.titleStyles}
                                   alt={item.desc || "Image"}/>
                        </Link>
                    );
                })}
            </div>
            {props.isMenuOpen ? <Menu toggleMenu={props.toggleMenu}/> : null}
        </div>
    );
};

export default Mosaic;