import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './video.module.css';
import { ReactComponent as PlayIcon } from './play_arrow-24px.svg';
import { ReactComponent as PauseIcon } from './pause-24px.svg';
import { ReactComponent as MuteIcon } from './volume_off-24px.svg';
import { ReactComponent as Replay10Icon } from './replay_10-24px.svg';
import { ReactComponent as Forward10Icon} from './forward_10-24px.svg';
import { ReactComponent as RestartIcon } from './replay-24px.svg';
import { ReactComponent as VolumeIcon } from './volume_up-24px.svg';
import { formatDuration } from "../../../common/functions";
import { Store } from '../../../Store';

const Video = props => {
    const { dispatch } = useContext(Store);
    const [ isPlaying, setIsPlaying ] = useState(false);
    const [ isFinished, setIsFinished ] = useState(false);
    const [ volume, setVolume ] = useState(1);
    const [ currentTime, setCurrentTime ] = useState(0);
    const [ duration, setDuration ] = useState(0);
    const [ activityHistory, setActivityHistory ] = useState([]);
    const [ videoSrc, setVideoSrc ] = useState("");
    const [ poster, setPoster ] = useState("");
    const vidEle = useRef(null);
    const progressEle = useRef(null);
    const overlayEle = useRef(null);

    const wrapUrl = (url) => {
        if (!url){
            return ""
        }
        const httpIndexes = [...url.matchAll(new RegExp('http', 'gi'))].map( a => a.index);
        return (!!window.wrapUrl && httpIndexes.length < 2) ? window.wrapUrl(url) : url;
    };

    const ensurePause = () => {
        vidEle.current.pause();
    };

    const registerActivity = type => {
        let activity = [...activityHistory];
        activity.push(type);
        setActivityHistory(activity);
    };

    const videoClick = () => {
        if ('current' in overlayEle){
            overlayEle.current.classList.remove('videoFade');
            isPlaying ? vidEle.current.pause() : vidEle.current.play();
            registerActivity('filter');
        }
    };
    const pauseClick = () => {
        if ('current' in vidEle){
            vidEle.current.pause();
            registerActivity("pause");
        }
    };

    const playClick = () => {
        if ('current' in vidEle){
            vidEle.current.play();
            registerActivity("play");
        }
    };

    const restartClick = () => {
        if ('current' in vidEle){
            vidEle.current.pause();
            vidEle.current.currentTime = 0;
            vidEle.current.play();
            registerActivity("restart");
        }
    };
    const replay = () => {
        if ('current' in vidEle){
            if(vidEle.current.currentTime > 10){
                vidEle.current.currentTime -= 10;
            } else {
                vidEle.current.currentTime = 0;
            }
            registerActivity("replay");
        }
    };
    const forward = () => {
        if ('current' in vidEle){
            if(vidEle.current.currentTime + 10 < vidEle.current.duration){
                vidEle.current.currentTime += 10;
            } else {
                vidEle.current.currentTime = vidEle.current.duration;
            }
            registerActivity("forward");
        }
    };
    const mute = () => {
        if ('current' in vidEle){
            vidEle.current.volume = vidEle.current.volume === 0 ? 1 : 0;
            registerActivity("mute");
        }
    };
    const progressClick = e => {
        const _src = e.nativeEvent.target;
        const cont = _src.classList.value.includes('fill') ? _src.parentNode : _src;
        let timeRatio = e.nativeEvent.offsetX / cont.clientWidth;
        //vidEle.current.currentTime =  parseFloat((percentage * vidEle.duration).toFixed(6));
        vidEle.current.currentTime = timeRatio * vidEle.current.duration;
    };



    useEffect( () => {
        const pauseInactive = () => {
            if ('current' in vidEle){
                dispatch({
                    type: 'SET_INACTIVE_PAUSED',
                    payload: vidEle.current.currentTime
                });
            }
        };

        if (!('current' in vidEle)){
            return;
        }
        let cVideo = vidEle.current;
        cVideo.onload = () => {
            setDuration(vidEle.current.duration);
            setCurrentTime(vidEle.current.currentTime);
            setActivityHistory( prevHistory => [...prevHistory, 'init']);
        };
      cVideo.onplay = () => {
          setIsPlaying(true);
          setIsFinished(false);
      };
      cVideo.onpause = () => {
          setIsPlaying(false);
          setIsFinished(false);
      };
      cVideo.onended = () => {
          setIsFinished(true);
          setIsPlaying(false);
          dispatch({
              type: 'MEDIA_FULLSCREEN',
              payload: false
          });
      };
      cVideo.onvolumechange = () => {
          setVolume(vidEle.current.volume);
      };
      cVideo.ontimeupdate = (e) => {
          pauseInactive();
          setCurrentTime(cVideo.currentTime);
          setDuration(cVideo.duration);
          progressEle.current.style.width = (cVideo.currentTime * 100 / cVideo.duration) + "%";
      };
        return () => {
                cVideo.ontimeupdate = null;
                cVideo.onload = null;
                cVideo.onpause = null;
                cVideo.onended = null;
                cVideo.onplay = null;
                cVideo.onvolumechange = null;
        };
    }, [vidEle, dispatch]);




    useEffect( () => {
        if (!('current' in overlayEle) || !overlayEle.current){
            return;
        }
        overlayEle.current.classList.remove('videoFade');
        if (!vidEle.current.paused || activityHistory.length === 0){
            let timer = setTimeout( () => {
                overlayEle.current.classList.add('videoFade');
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [activityHistory]);


    useEffect( () => {
        if ('poster' in props){
            setPoster(wrapUrl(props.poster));
        }
        if ('src' in props){
            setVideoSrc(wrapUrl(props.src));
        }
    }, [props]);


    return(
        <div className={styles.container} id="test-video">
                <div className={styles.video_container}>
                    <video ref={vidEle}
                           onClick={videoClick}
                           onTouchEnd={ensurePause}
                           className={styles.video}
                           src={videoSrc}
                           poster={poster}
                           data-testid="video-tag"
                           controls={!props.overlay}
                           autoPlay={props.autoplay}/>
                    { props.overlay ?
                        <div ref={overlayEle} className={styles.overlay}>
                            <div className={styles.overlay_time_container}>
                                <div className="test-currentTime" data-testid="currentTime-tag">{ formatDuration(currentTime) }</div>
                                <div id="test-duration" data-testid="duration-tag">{ formatDuration(duration) }</div>
                            </div>
                            <div className={styles.overlay_progress_container}>
                                <div className={styles.overlay_progress_bg} onClick={progressClick} data-testid="progress-click-tag">
                                    <div ref={progressEle} className={styles.overlay_progress_value + " fill"} data-testid="progress-tag"/>
                                </div>
                            </div>
                            <div className={styles.overlay_controls_container}>
                                <div className={styles.overlay_play}>{isPlaying ? <PauseIcon data-testid="test-pause" onClick={pauseClick}/> : isFinished ? <RestartIcon data-testid="test-restart" onClick={restartClick}/> : <PlayIcon data-testid="test-play" onClick={playClick}/>}</div>
                                <div className={styles.overlay_replay10} onClick={replay} data-testid="replay-tag"><Replay10Icon/></div>
                                <div className={styles.overlay_forward10} onClick={forward} data-testid="forward-tag"><Forward10Icon/></div>
                                <div className={styles.overlay_mute} onClick={mute} data-testid="mute-tag">{ volume > 0 ? <VolumeIcon/> : <MuteIcon/> }</div>
                            </div>
                        </div>
                        : null}
                </div>
        </div>
    );
};

export default Video;