import React, { useState, useEffect, useContext, useRef } from 'react';
import SliderMenu from "./SliderMenu/slidermenu.js";
import LanguageIcon from './LanguageIcon/languageicon.js';
import LanguageOverlay from "./LanguageOverlay/languageoverlay";
import { Store } from '../../../Store.js';
import styles from './main.module.css';
import './main.module.css';
import {getImageByDim, getImageSrcSet, getPresLink} from "../../../common/functions";
import { Redirect } from "react-router-dom";
import Loader from "../../Loader/loader";

const Main = () => {
    const { state } = useContext(Store);
    const [isLangToggleOn, setIsLangToggleOn] = useState(false);
    const [ bgImage, setBgImage ] = useState('');
    const [ bgImageSet, setBgImageSet ] = useState('');
    const [ redirectTo, setRedirectTo ] = useState("");
    const imgEle = useRef(null);

    const changeLang = () => {
        setIsLangToggleOn(!isLangToggleOn);
    };


    const initSrcSet = () => {
        if (!(imgEle.current)){
            return;
        }
        //if (bgImageSet) return;
        let srcSet = getImageSrcSet(bgImage);
        setBgImageSet(srcSet);
    };

    useEffect( () => {
        if (state.isFetching === false && state.kiosk && 'defaultPresentationId' in state &&
            typeof state.defaultPresentationId === 'number' &&
            'presentations' in state.kiosk){
            const link = getPresLink(state.defaultPresentationId, state.kiosk.presentations);
            //history.push(link);
            setRedirectTo(link);
        }
        if(state.kiosk && 'device' in state.kiosk){
            let device = state.kiosk.device[0];
            let bgResource = 'resources' in device && device.resources.find(item => item && item.type === 'idle_screen_background');
            if (bgResource){
                let src = getImageByDim(bgResource.urls[0].url, '167x167');
                setBgImage(src);
            } else {
                setBgImage('');
            }
        }
    }, [state.kiosk, state]);

    return (
        <div className={styles.container + ` page`}>
            { redirectTo ? <Redirect to={redirectTo}/> : null}
            <div className={styles.header_container}>
                 <h1 className={styles.header1}>
                     { /*{ locale[state.localeSettings.locale]['mainPageTitle'] || ""}*/ }
                 </h1>

                { state.localeSettings.locales.length > 1 ?
                    <div onClick={changeLang} className={styles.langIcon + ' lang'} data-testid="lang-tag">
                        <LanguageIcon/>
                    </div>
            : null                }
            </div>

            {isLangToggleOn ? <LanguageOverlay changeLang={changeLang}/> : null}

            <div className={styles.slider_menu}>

                { state.isFetching ? <Loader/> : <SliderMenu/> }
            </div>
            { bgImage ?
                <div className={styles.background_image}>
                    <img
                        ref={imgEle}
                        sizes="100vw"
                        onLoad={initSrcSet}
                        src={window.wrapUrl(bgImage)}
                        srcSet={bgImageSet}
                        data-testid="bgimg-tag"
                        alt=""/>
                </div> : null
            }
        </div>
    );

};

export default Main;
