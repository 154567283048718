import React from 'react';
import styles from './linelabel.module.css';
import {Link} from "react-router-dom";
import {getUrlPath} from "../../../../common/functions";

const LineLabel = (props) => {
    const textOffset = "37px";
    const resetTextOffset = "unset";
    const alignOffset = "15px";
    const offsetType = 'text_placement' in props.label['map_image'] ? props.label['map_image']['text_placement'] : "left";
    const titleAlignment = ('anchorTitleData' in props.label &&
                            'alignment' in props.label.anchorTitleData && props.label.anchorTitleData.alignment === 'right') ? "right" : "left";
    const descAlignment = ('anchorDescData' in props.label &&
        'alignment' in props.label.anchorDescData && props.label.anchorDescData.alignment === 'right') ? "right" : "left";
    const titleSize = props.label.anchorTitleData['font-size'] === 'medium' || props.label.anchorTitleData['font-size'] === '' ? "12px" : (props.label.anchorTitleData['font-size'] === 'large' ? "18px" : "8px");
    const descSize = props.label.anchorDescData['font-size'] === 'medium' || props.label.anchorDescData['font-size'] === '' ? "22px" : (props.label.anchorTitleData['font-size'] === 'large' ? "30px" : "16px");

    const labelStyles = {
        anchorLabel: {
            left: ((props.label['map_image']['anchor_x'] / props.map.width) * 100) + "%",
            top: ((props.label['map_image']['anchor_y'] / props.map.height) * 100) + "%"
        },
        textLabel: {
            left: ((props.label.x / props.map.width) * 100) + "%",
            top: ((props.label.y / props.map.height) * 100) + "%"
        }
    };
    const textStyles = {
        left: {
            left: "unset",
            top: props.label.y < props.label['map_image']['anchor_y'] ? alignOffset : resetTextOffset,
            bottom: props.label.y >= props.label['map_image']['anchor_y'] ? alignOffset : resetTextOffset,
            right: textOffset,
        },
        top: {
            left: props.label.x <= props.label['map_image']['anchor_x'] ? alignOffset : resetTextOffset,
            top: "unset",
            bottom: textOffset,
            right: props.label.x > props.label['map_image']['anchor_x'] ? alignOffset : resetTextOffset,

        },
        bottom: {
            left: props.label.x <= props.label['map_image']['anchor_x'] ? alignOffset : resetTextOffset,
            top: textOffset,
            bottom: "unset",
            right:  props.label.x > props.label['map_image']['anchor_x'] ? alignOffset : resetTextOffset

        },
        right: {
            left: textOffset,
            top: props.label.y < props.label['map_image']['anchor_y'] ? alignOffset : resetTextOffset,
            bottom: props.label.y >= props.label['map_image']['anchor_y'] ? alignOffset : resetTextOffset,
            right: "unset"

        },
        title: {
            color: props.label.anchorTitleData.color,
            fontSize: titleSize,
            textAlign: titleAlignment
        },
        desc: {
            color: props.label.anchorDescData.color,
            fontSize: descSize,
            textAlign: descAlignment
        }
    };
    const calcLineStyles = ({x: x1, y: y1}, {map_image: {anchor_x: x2, anchor_y: y2}}, {height: hMax, width: wMax}) => {
       let lineWidthPercent = Math.abs(x1 - x2 ) * 100 / wMax;
       let lineHeightPercent = Math.abs(y1 - y2) * 100 / hMax;
       let startX = (x1 < x2) ? x1 : x2;
       let startY = (y1 < y2) ? y1 : y2;
       let borderStyle = "3px solid white";
       let borderRadius = "9px";
       let borderNone = "none";
       let borderTop = (y1 > y2) ? borderStyle : borderNone;
       let borderRight = (x1 > x2) ? borderStyle : borderNone;
       let borderLeft =  (x2 >= x1) ? borderStyle : borderNone;
       let borderBottom = (y2 >= y1) ? borderStyle : borderNone;
       let borderTopLeftRadius = (x2 > x1 && y1 > y2) ? borderRadius : borderNone;
       let borderBottomLeftRadius = (x2 > x1 && y2 > y1) ? borderRadius : borderNone;
       let borderBottomRightRadius = (x1 > x2 && y2 > y1) ? borderRadius : borderNone;
       let borderTopRightRadius = (x1 > x2 && y1 > y2) ? borderRadius : borderNone;
        return {
            left: (startX * 100 / wMax) + "%",
            top: (startY * 100 / hMax) + "%",
            width: lineWidthPercent + "%",
            height: lineHeightPercent + "%",
            borderBottom: borderBottom,
            borderRight: borderRight,
            borderLeft: borderLeft,
            borderTop: borderTop,
            borderTopLeftRadius: borderTopLeftRadius,
            borderBottomLeftRadius: borderBottomLeftRadius,
            borderBottomRightRadius: borderBottomRightRadius,
            borderTopRightRadius: borderTopRightRadius
        };
    };


    return(
        <Link to={getUrlPath(props.label.type) + '/' + props.label['record_id']}>
           <div className={styles.label_text} style={labelStyles.textLabel}>
               <div className={styles.text} style={textStyles[offsetType]}>
                   {props.label.anchorTitleData.content ? <div className={styles.title} style={textStyles.title}>{props.label.anchorTitleData.content}</div> : null}
                   {props.label.anchorDescData.content ? <div className={styles.desc} style={textStyles.desc}>{props.label.anchorDescData.content}</div> : null}
               </div>
           </div>
           <div className={styles.line} style={calcLineStyles(props.label, props.label, props.map)}/>
           <div className={styles.label} style={labelStyles.anchorLabel}>
               <div className={styles.dot}/>
           </div>
        </Link>
    );
};

export default LineLabel;