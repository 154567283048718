import React, { useState, useEffect} from 'react';
import ImageInfoBox from "./ImageInfoBox/imageinfobox";
import styles from "./imageview.module.css";
import './imageview.module.css';
import HeaderRowFullscreen from "../../Framework/HeaderRowFullscreen/headerrowfullscreen";
import Slider from "./Slider/slider";
import Pagination from "../Article/Page/Block/Pagination/pagination";

function ImageView(props){
    const [isInfoShown, setIsInfoShown] = useState(false);
    const [ infoIndex, setInfoIndex ] = useState(null);
    const [ haveInfoText, setHaveInfoText ] = useState(false);


    const haveText = item => {
        let isFound = false;
        if (item){
            if ('title' in item && typeof item.title === 'string' && item.title.length > 0)
                isFound = true;
            if ('desc' in item && typeof item.desc === 'string' && item.desc.length > 0)
                isFound = true;
        }
        return isFound;
    };

    useEffect( () => {
        if (infoIndex === null){
            let startIndex = 'startIndex' in props ? props.startIndex : 0;
            setInfoIndex(startIndex);

        }
    }, [infoIndex, props]);

    console.log("Gallery props:", props.items);
    useEffect( () => {
        if (typeof infoIndex === 'number'){
            let item = props.items[infoIndex];
            let haveTitleOrDesc = haveText(item) || item.credits.length > 0;
            setHaveInfoText(haveTitleOrDesc);
            console.log(props.items);
            if (!haveTitleOrDesc){
                setIsInfoShown(false);
            }
        }
    }, [infoIndex, props.items]);

    const toggleHandler = () => {
        setIsInfoShown(!isInfoShown);
    };

    const toggleInfoIndex = (idx) => {
        setInfoIndex(idx);
    };

    return(
        <div className={styles.container}>
            <HeaderRowFullscreen haveText={haveInfoText} toggleHandler={toggleHandler}/>
            <div>
            { props && 'items' in props ? <Slider startSlide={props.startIndex} items={props.items} toggleHandler={toggleHandler} toggleInfoIndex={toggleInfoIndex}/> : null }
            </div>
            { isInfoShown ? <ImageInfoBox
                toggleHandler={toggleHandler}
                title={props.items[infoIndex].title}
                credits={props.items[infoIndex].credits}
                desc={props.items[infoIndex].desc}/> : null }
            <div className={styles.label}>
                { /*props.items[infoIndex] && props.items[infoIndex].credit ?
                    <Label text={props.items[infoIndex].credit}/>
                    : null */}
            </div>
            { typeof infoIndex === 'number' && props.items.length > 1  ?  <Pagination pos={infoIndex + 1} total={props.items.length}/> : null }
        </div>
    );
}

export default ImageView;