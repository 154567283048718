import React, { useState, useEffect, useContext } from 'react';
import styles from './caption.module.css';
import { getTranslatedCreditType } from "../../common/functions";
import { Store } from "../../Store";

const Caption = props => {
    const { state } = useContext(Store);
    const [ credits, setCredits ] = useState([]);


    useEffect( () => {
        if (props.credits){
            if (Array.isArray(props.credits)){
                let _credits = props.credits.map( item => {
                    let credit = {};
                    credit.type = getTranslatedCreditType(item.type, state.localeSettings.locale);
                    credit.name = item.name || '';
                    return credit;
                })
                setCredits(_credits);
            } else if (typeof props.credits === 'string'){
                let credit = {type: "Photo", name: props.credits};
                setCredits([credit]);
            }
        }
    }, [props, state.localeSettings.locale]);

    return(
        <div className={styles.container}>
            <div className={styles.title}>{ props.title }</div>
            <div className={styles.desc}>{ props.desc }</div>
            { credits.length > 0 ? <div className={styles.credits}>
                { credits && credits.map( (credit, i) => {
                    return(`${credit.type}: ${credit.name}${i < credits.length - 1 ? ', ' : ''}`);
                })}
            </div> : null}
        </div>
    );
}

export default Caption;

