import React, { useState, useEffect, useRef } from  'react';
import styles from './label.module.css';
import { ReactComponent as Marker } from "../marker.svg";
import Image from "../../../Framework/Image/image";
import {Link} from "react-router-dom";
import {getUrlPath} from "../../../../common/functions";

const Label = props => {
    const [ imagePosition, setImagePosition ] = useState( ["x1" , "y1"]);
    const linkEl = useRef(null);

    const contStyles = {
        left: ((props.label.x / props.map.width) * 100) + "%",
        top: ((props.label.y / props.map.height) * 100) + "%",
        transform: `scale(${props.scale})`
    };
    const cStyles = {
        linkStyles: {
            transform: `scale(${props.scale})`,
            left: `${imagePosition[0] === 'x1' ? '69px' : 'unset'}`,
            right: `${imagePosition[0] === 'x0' ? '69px' : 'unset'}`,
            top: `${imagePosition[1] === 'y1' ? '-93px' : 'unset'}`,
            bottom: `${imagePosition[1] === 'y0' ? '-93px' : 'unset'}`
        },
        labelStyles: {
            transform: `translate(-45%, -80%)`,
        }
    };

    const getArrowStyles = (imgPos) => {
        let style = {};
        let x = imgPos[0], y = imgPos[1];
        if (y === 'y1'){
            style.top = '86px';
        } else {
            style.bottom = '86px';
        }
        if (x === 'x0'){
            style.right = 'calc(var(--mapimage-arrow-size) * -4)';
            style.transform = 'translate(-106%, -100%) rotate(-270deg)';
        } else {
            style.transform = 'translate(-93%, -100%) rotate(-90deg)';
        }
        //insert resets for rest
        style = {top: 'unset', left: 'unset', right: 'unset', bottom: 'unset', ...style};
        return style;
    };

    const labelClick = (id) => {
       const x = ((props.label.x / props.map.width) > 0.5) ? "x0" : "x1";
       const y = ((props.label.y / props.map.height) > 0.5) ? "y0" : "y1";
       setImagePosition([x, y]);
       props.toggleInfoBox(id);
    };

    const removeFocus = () => {
        let list = document.getElementsByClassName('focus');
        let arr = [...list];
        if (Array.isArray(arr) && arr.length > 0){
            arr.forEach( item => {
                item.classList.remove('focus');
            });
        }
    };

    useEffect( () => {
        removeFocus();
        if(linkEl.current){
            linkEl.current.classList.add(imagePosition[0], imagePosition[1]);
            linkEl.current.parentNode.classList.add('focus');
        }
    }, [imagePosition]);

    return(<div  className={styles.container} style={contStyles}>
        <Marker onClick={() => {labelClick(props.label.record_id);}} data-testid="label-tag" className={styles.label} style={cStyles.labelStyles}/>
        { props.label.isActive ?
                <Link ref={linkEl} to={getUrlPath(props.label.type) + '/' + props.label['record_id']} className={styles.infobox} style={cStyles.linkStyles}>
                    <div className={styles.infobox_arrow} style={getArrowStyles(imagePosition)}/>
                    <Image src={props.label.image} sizes="30vw"/>
                    { 'title' in props.label ? <div className={styles.title}>{props.label.title}</div> : null }
                    { 'desc' in props.label ? <div className={styles.desc}>{props.label.desc}</div>: null }
                </Link>
        : null }
    </div>);
};

export default Label;