import React from 'react';
import styles from './pagenavitem.module.css';

const PageNavItem = props => {
    const pageString = props.index ? props.index.toString() : '?';
    const selectedStyles = {
        container: {
            backgroundColor: 'var(--theme-detailColor)',
            border: 'unset'
        },
        item: {
           fontWeight: "700"
        }
    }

    const clickHandler = () => {
        props.action(props.index);
    };

    return(
        <div onClick={clickHandler} data-testid="pagenavitem-tag" className={styles.container} style={props.selected ? selectedStyles.container : {}}>
            <div className={styles.item} style={props.selected ? selectedStyles.item : {}}>{`${pageString}. ${props.text}`}</div>
        </div>
    );
};

export default PageNavItem;