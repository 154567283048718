import React from 'react';
import styles from './loader.module.css';
import { ReactComponent as LoaderIcon } from './loader.svg';

const Loader = () => {
    return(<div>
        <LoaderIcon className={styles.svg}/>
    </div>);
};

export default Loader;

