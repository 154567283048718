import React, { useEffect, useState, useContext } from 'react';
import SliderMenuItem from '../SliderMenuItem/slidermenuitem.js';
import { getUrlPath } from '../../../../common/functions.js';
import { Link } from 'react-router-dom';
import styles from './slidermenu.module.css';
import { Store } from '../../../../Store.js';
import { getTypeName } from '../../../../common/functions.js';

import './slidermenu.module.css';
import Loader from "../../../Loader/loader";

const SliderMenu = () => {
    const { state } = useContext(Store);
    const [ view, setView ] = useState({});

    useEffect( () => {
       if (typeof state.kiosk === 'object' && 'presentations' in state.kiosk) {
           let presentations = [...state.kiosk.presentations];
           let loc = state.localeSettings.locale;
           if (presentations) {
               presentations.forEach(item => {
                   item.title = item.contents[loc].title ? item.contents[loc].title : getTypeName(item.type);
                   const { resources } = item;
                   if (resources){
                       resources.forEach( res => {
                           if ('type' in res && res.type === 'icon') {
                               const [urls] = res.urls;
                               if (urls && 'url' in urls) {
                                   item.image = urls.url;
                               }
                           }
                       });
                   }
               });
               setView( {presentations});
           }
       }


    }, [state.kiosk, state.localeSettings.locale]);

    return (
        <div className="slider-menu">
            <div className={styles.list_container}>

                { !state.isFetching && 'presentations' in view ? view.presentations.map( (item, i) => {
                    return(<div key={i} data-testid="listitem-tag">
                        <Link to={getUrlPath(item.type) + '/' + ( item.type === 'single' ? item.children[0]['record_id'] : item.id)}>
                            <SliderMenuItem text={item.contents[state.localeSettings.locale].title || getTypeName(item.type, state.localeSettings.locale)} type={item.type} image={item.image}/>
                        </Link>
                    </div>);
                })
                    : state.override.isActive ? <Loader/> : <div></div> }
            </div>
        </div>
    );
};


export default SliderMenu;
