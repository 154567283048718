import React from 'react';
import { ReactComponent as IconNo } from "./no.svg";
import { ReactComponent as IconGb } from "./gb.svg";
import { ReactComponent as IconSv } from "./se.svg";
import { ReactComponent as IconDe } from "./de.svg";
import { ReactComponent as IconEs } from "./es.svg";
import { ReactComponent as IconFr } from "./fr.svg";
import './languageitem.css';

function LanguageItem(props){
        const width = 85;
        const height = 85;
        const langAr = [
            {obj: IconNo, lang: "no", desc: "Norsk"},
            {obj: IconGb, lang: "en", desc: "English"},
            {obj: IconSv, lang: "sv", desc: "Svenska"},
            {obj: IconDe, lang: "de", desc: "Deutsche"},
            {obj: IconEs, lang: "es", desc: "Española"},
            {obj: IconFr, lang: "fr", desc: "Français"}
        ];

        let lang = langAr.find( item => {
            return (props.lang === item.lang) ? item : false;
            }
        );

        let Icon = (lang && 'obj' in lang) ? lang.obj : false;
        if (Icon === false){ // No valid language
            return;
        }
        return(
            <div onClick={'toggleLanguage' in props ? props.toggleLanguage : ""} lang={props.lang} className="language-item" data-testid="flag-tag">
                <Icon width={width} height={height} className={props.lang === props.selectedLang ? "language-flag selected" : "language-flag"}/>
                <div className="language-item-text">{lang.desc || ""}</div>
            </div>
        );
}

export default LanguageItem;