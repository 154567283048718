import React from 'react';
import './imageinfobox.css';
import Credit from "../../../Credit/credit";

const ImageInfoBox = props => {
    console.log(props);
    return(
        <div className="imageinfobox">
            <div className="imageinfobox-filter" onClick={props.toggleHandler}/>
            <div className="imageinfobox-content">
                <div className="imageinfobox-content-container">
                    <div className="imageinfobox-content-item">
                        <div className="imageinfobox-content-flick"/>
                        <h3>
                            {props.title}
                        </h3>
                        <p>
                            {props.desc}
                        </p>
                    </div>
                        <div className="imageinfobox-content-item">
                            { Array.isArray(props.credits) && props.credits.length > 0 ?
                            <div className='imageinfobox-credit'>
                                <Credit credits={props.credits}/>
                            </div>
                                : null }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageInfoBox;