import locale from '../common/locale.json';

export const getUrlPath = (type) => {
    let devicePath = getDevicePath();
    if (type === 'home'){
        return devicePath;
    }
    let typePath = "";
    switch (type) {
        case 'mosaic':
            typePath = "/mosaic";
            break;
        case 'image_gallery':
            typePath = "/photogallery";
            break;
        case 'video_gallery':
            typePath =  "/videogallery";
            break;
        case 'map_image':
            typePath = "/imagemap";
            break;
        case 'single':
            typePath = "/article";
            break;
        case 'index':
            typePath = "/list";
            break;
        case 'timeline':
            typePath = "/timeline";
            break;
        case 'slideshow':
            typePath = "/slideshow";
            break;
        default:
            typePath = "/article";
    }
    return devicePath + typePath;
};
export const getEnvironment = () => {
    const host = window.location.hostname;
    if (host.split('localhost').length > 1){
        return 'local';
    }
    return host.split('beta').length > 1 ? 'dev' : 'prod';
};
export const getDevicePath = () => {
    let filterParams = window.location.hash.split('?')[0];
    let pathAr = filterParams.split('/');
    let id = null;
    pathAr.forEach( (item, i) => {
       if(item === 'device' && pathAr.length - 1 >= i){
           id = pathAr[i+1];
       }
    });
    return '/device/' + id;
};
export const getTranslation = (loc, key) => {
    let transText = "";
    try {
        transText = locale[loc][key];
    } catch ( err ){
        return false;
    }
    return transText;
}
export const getHashParams = search => {
    try {
        search = search.includes('?') ? search.split('?')[1] : search;
        let paramsAr = search.split('&');
        let params = {};
        paramsAr.forEach( item => {
            const itemAr = item.split('=');
            if (itemAr.length === 2){
                params[itemAr[0]] = itemAr[1];
            }
        });
        return params;
    } catch (err){
        return false;
    }
};
export const getTypeName = (type, lang = "no") => {
    if (!(lang in locale)){
        return false;
    }
    let { [lang]: transObj } = locale;
    let {
        videoGallery,
        mosaic,
        imageGallery,
        timeline,
        single,
        list,
        mapImage,
        autoplay,
        home
        } = transObj;
    switch (type) {
        case 'mosaic':
            return mosaic || "Mosaic";
        case 'image_gallery':
            return imageGallery || "Image Gallery";
        case 'video_gallery':
            return videoGallery || "Video Gallery";
        case 'map_image':
            return mapImage || "Map Image";
        case 'single':
            return single || "Single";
        case 'index':
            return list || "List";
        case 'timeline':
            return timeline || "Timeline";
        case 'slideshow':
            return autoplay || "Autoplay";
        case 'home':
            return home || "Home";
        default:
            return false;
    }
};

export const extractElementData = (item) => {
    if (typeof item !== 'object' || Array.isArray(item)){
        return false;
    }
   const isVideo = ('type' in item && item.type === 'video');
   const isImage = ('type' in item && item.type ==='image');
   if (!isVideo && !isImage){
       return false;
   }
   let obj = {};
   let url;
   try {
       url = item.url[0].url;
   } catch( err ){
       url = ''
   }
   if (isVideo){
       obj.type = 'video';
       obj.video = url;
   } else if (isImage){
       obj.type = 'record';
       obj.image = url;
   }
    obj.title = getVarWFallback(item.contents, 'title');
    obj.desc = getVarWFallback(item.contents, 'description');
   try {
       obj.credits = item.credits;
   } catch ( err ){
       // ignore
   }
    return obj;
};
export const fallbackArray = () => {
    return ["no", "sv", "en", "de", "es", "fr"];
};
export const getPosterUrl = (url) => {
    try{
        let poster = url.split('multimedia');
        let [ suffix ] = poster[1].split(".");
        poster = poster[0] + "image" + suffix;
        return poster;
    } catch( err ){
        return ''
    }
};
export const getVarWFallback = (obj, attr) => {
    if (typeof obj !== 'object'){
        return false;
    }
    let fallbackLang = fallbackArray();
    let langWithSufficientData;
    langWithSufficientData = fallbackLang.find( (lang) => {
        return (checkNested(obj, lang, attr) &&
                obj[lang][attr] &&
                typeof obj[lang][attr] === 'string' &&
                obj[lang][attr].length > 0)
    });
    if (typeof langWithSufficientData === 'undefined'){
        return "";
    }
    return (typeof obj[langWithSufficientData][attr] === 'string') ?
        obj[langWithSufficientData][attr] :
        obj[langWithSufficientData][attr].content;
};
export const getImage = (image) => {
    if (typeof image === 'undefined')
        return '';
    if (typeof image === 'string' && image.length > 3)
        return image;
    try{
        let img = image.urls[0].url;
        if (img && typeof img === 'string')
            return img;
    } catch (err){
        return '';
    }

};
export const getCreditOfRecord = record => {
    try{
        let credit = record.image.credit;
        return credit;
    } catch (err){
        return {};
    }
}
export const checkNested =  (obj, level,  ...rest) => {
    if (obj === undefined) return false;
    if (rest.length === 0 && obj.hasOwnProperty(level)) return true;
    return checkNested(obj[level], ...rest)
};
export const getPresLink = (id, presentations) => {
    let _id = typeof id === 'string' ? parseInt(id) : typeof id === 'number' ? id : false;
    if (!_id || !Array.isArray(presentations)){
        return '';
    }
    let item = presentations.find( x => x.id === _id);
    if (!item){
        return "";
    }
    if (item.type === 'single'){
        try {
            let recordId = item.children[0].record_id;
            return getUrlPath(item.type) + '/' + recordId;
        } catch (err){
            console.log("Could not get record_id of the solo article presentation");
            return "";
        }
    }
    return getUrlPath(item.type) + '/' + item.id;
};

export const getTranslatedCreditType = (type, loc) => {
    const data = {
        photo: {
            no: "Foto",
            sv: "Foto",
            en: "Photo",
            de: "Foto",
            fr: "Photo",
            es: "Foto"
        },
        video: {
            no: "Video",
            se: "Video",
            en: "Video",
            de: "Video",
            fr: "Vidéo",
            es: "Vídeo"
        },
        photographer: {
            no: "Fotograf",
            sv: "Fotograf",
            en: "Photographer",
            de: "Fotograf",
            fr: "Photographe",
            es: "Fotógrafo"
        },
        artist: {
            no: "Kunstner",
            sv: "Konstnär",
            en: "Artist",
            de: "Künstler",
            fr: "Artiste",
            es: "Artista"
        },
        author: {
            no: "Forfatter",
            sv: "Författare",
            en: "Author",
            de: "Autor(e)",
            fr: "Auteur(e)",
            es: "Autor(a)"
        },
        director: {
            no: "Regissør",
            sv: "Regissören",
            en: "Director",
            de: "Regisseur",
            fr: "Directeur",
            es: "Director"
        },
        voice: {
            no: "Stemme",
            sv: "Röstning",
            en: "Voice",
            de: "Abstimmung",
            fr: "Voix",
            es: "Voz"
        },
        owner: {
            no: "Eier",
            sv: "Ägare",
            en: "Owner",
            de: "Inhaber",
            fr: "Propriétaire",
            es: "Propietario"
        },
        rightsmanager: {
            no: "Rettighetsforvalter",
            sv: "Rättighetschef",
            en: "Rights Manager",
            de: "Haklar Müdürü",
            fr: "Gestionnaire des droits",
            es: "Gerente de derechos"
        }
    };
    try {
        return data[type][loc];
    } catch (err){
        return '';
    }
};
export const stripUrlParams = (url) => {
    if (!url || typeof url !== 'string'){
        return '';
    }
    return url.includes('?') > -1 ? url.split('?')[0] : url;
}
export const getImageByDim = (url, dim = '167x167') => {
    let src = stripUrlParams(url);
    if (src){
        return src + "?dimension=" + dim;
    }
    return src;
};
export const isUrl = data => {
    return typeof data === 'string' && data.includes('http');
};
export const getImageSrcSet = (url, notIncludeMax=false) => {
    if (typeof url !== 'string'){
        return false;
    }
    let src = stripUrlParams(url);
    let dimensions = notIncludeMax ? getDimensions().slice(0,-1) : getDimensions();
    let srcSet = '';
    if (src){
        dimensions.forEach( (item, i) => {
            srcSet += (!!window.wrapUrl ? window.wrapUrl(src) : src) + "?dimension=" + item + (i < dimensions.length - 1 ? ', ' : '');
        });
    }
    return srcSet;
};
export const getDimensions = () => {
    return ['167x167 167w',
        '250x250 250w',
        '400x400 400w',
        '600x600 600w',
        '800x800 800w',
        '1200x1200 1200w',
        'max 1400w'];
};
export const parseMediaFromRecord = (rec, type = "", loc = "") => {
    if (!rec || Object.entries(rec).length === 0){
        return [];
    }
    let getByLocale = loc.length > 0;
    let isTypeSet = type.length > 0;
    let { contents: allContents } = rec;
    if (typeof allContents === 'undefined'){
        return [];
    }
    let pages = [];
    if (getByLocale){
        pages = loc in allContents ? allContents[loc].pages : [];
    } else {
        for (let key of Object.keys(allContents)){
            if ('pages' in allContents[key]){
                pages = pages.concat(allContents[key].pages);
            }
        }
    }
    let blocks = [];
    pages.forEach( page => {
        if ('blocks' in page){
            blocks = blocks.concat(page.blocks);
        }
    });
    let elements = [];
    blocks.forEach( block => {
        if ('data' in block && block.type === 'image_video'){
            elements = elements.concat(block.data);
        }
    });
    if (isTypeSet){
        elements = elements.filter( x => x.type === type);
    }
    return elements;
};
export const formatDuration = (num) =>{
    if (typeof num !== 'number'){
        return false;
    }
    let h = Math.floor(num / 3600).toString();
    h = (h === "0") ? "" : h + ":";
    let  m = Math.floor(num / 60).toString();
    m = ((m.length === 1) ? ("0" + m) : m) + ":";
    let s = Math.floor(num % 60).toString();
    s = (s.length === 1) ? ("0" + s) : s;
    return (h + m + s);
};
export const getPres = (array, path) => {
    let pathAr = path.split('/');
    pathAr.shift();
    if (pathAr[0] === 'device'){
        pathAr.splice(0, 2);
    }
    let presId = (pathAr.length > 1) ? parseInt(pathAr[1]) : false;
    let pres = array.filter(x => x.id === presId)[0];
    return pres ? pres : false;
};