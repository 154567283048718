import React from 'react';
import { ReactComponent as SingleIcon } from './single.svg';
import { ReactComponent as MosaicIcon } from './mosaic.svg';
import { ReactComponent as ImageGalleryIcon } from './image_gallery.svg';
import { ReactComponent as VideoGalleryIcon } from './video_gallery.svg';
import { ReactComponent as MapIcon } from './map.svg';
import { ReactComponent as ListIcon } from './list.svg';
import { ReactComponent as TimelineIcon } from './timeline.svg';
import styles from './slidermenuitem.module.css';
import Image from "../../../Framework/Image/image";

function SliderMenuItem(props) {
    const getImage = (type) => {
        switch (type) {
            case 'single':
                return <SingleIcon/>;
            case 'image_gallery':
                return <ImageGalleryIcon/>;
            case 'video_gallery':
                return <VideoGalleryIcon/>;
            case 'mosaic':
                return <MosaicIcon/>;
            case 'index':
                return <ListIcon/>;
            case 'map_image':
                return <MapIcon/>;
            case 'timeline':
                return <TimelineIcon/>;
            case 'slideshow':
                return <ListIcon/>;
            default:
                return <SingleIcon/>;
        }
    };

    return (
        <div className={styles.container}>
            { props.image ?
                <Image sizes="20vw" src={props.image}/>
            :
                <div className={styles.circle}>
                    <div className={styles.img} id="'icon-div">
                        { getImage(props.type) }
                    </div>
                </div>
            }

            <div className={styles.text} data-testid="slideritemtext-tag">{props.text}</div>
        </div>
    );
}

export default SliderMenuItem;
