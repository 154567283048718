import React, {useState, useEffect, useContext} from 'react';
import HeaderRow from "../../Framework/HeaderRow/headerrow";
import Menu from "../../Framework/Menu/menu";
import {Store} from "../../../Store";
import history from '../../../history';
import Gallery from "../../Gallery/gallery";
import {
    getPosterUrl,
    getPres,
    getTypeName,
    parseMediaFromRecord
} from "../../../common/functions";
import Loader from "../../Loader/loader";
import styles from './videogallery.module.css';

const VideoGallery = props => {
    const {state} = useContext(Store);
    const [isLoading, setIsLoading] = useState(true);
    const [presentation, setPresentation] = useState({});
    const [view, setView] = useState({});

    useEffect(() => {
        if ('presentations' in state.kiosk) {
            let pres = getPres(state.kiosk.presentations, history.location.pathname);
            pres.records = 'records' in state.kiosk ? state.kiosk.records : [];
            setPresentation(pres);
        }
    }, [state.kiosk]);


    useEffect(() => {
        let loc = state.localeSettings.locale;
        let pres = presentation;
        let _view = {};
        _view.title = 'contents' in pres ? pres.contents[loc].title : getTypeName(pres.type, loc);
        _view.type = pres.type;
        let records = 'children' in pres ? pres.children.map(x => pres.records.find(y => x['record_id'] === y.id)) : [];
        let videos = [];
        records.forEach((rec) => {
            videos = videos.concat(parseMediaFromRecord(rec, "video", loc));
        });
        let formattedVideos = [];
        videos.forEach(video => {
            let vid = {};
            let content = loc in video.contents ? video.contents[loc] : false;
            vid.title = content && 'title' in content ? content.title : '';
            vid.desc = content && 'description' in content ? content.description : '';
            vid.type = 'video';
            vid.video = video.url[0].url;
            vid.credits = video.credits;
            vid.image = getPosterUrl(vid.video);
            if ('video' in vid) formattedVideos.push(vid);
        });
        _view.allVideos = formattedVideos;
        setView(_view);
    }, [presentation, state.localeSettings.locale]);

    return (
        <div className="page">
            {isLoading ? <div className={styles.loader_container}><Loader/></div> : null}
            <HeaderRow title={view.title} toggleMenu={props.toggleMenu}/>

            {view.allVideos ?
                <Gallery isLoading={isLoading} setIsLoading={setIsLoading} items={view.allVideos}/>
                : null}
            {props.isMenuOpen ? <Menu toggleMenu={props.toggleMenu}/> : null}
        </div>
    );
};

export default VideoGallery;