import React, { useEffect } from 'react';
import styles from './error.module.css';
import Button from "../Button/button";
import {API_RESTART_TIMER} from "../../common/settings";

const Error = props => {
    const [countdown, setCountdown] = React.useState(API_RESTART_TIMER);
    const [ isRedirecting, setIsRedirecting ] = React.useState(false);

    useEffect( () => {
        let timer = setInterval( () => {
            setCountdown( prev => {
                return prev - 1;
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect( () => {
        if(countdown <= 0 && 'action' in props && isRedirecting === false){
            setIsRedirecting(true);
            'action' in props && props.action();
        }
    },[countdown, props, isRedirecting]);


    return(<div data-testid="error-tag" className={styles.container}>
        <div className={styles.content}>
            <div className={styles.header}>Error</div>
            <div className={styles.info}> { props.text ? props.text : "Could not load and parse data from API" }.</div>
            <div className={styles.info} id="timer">{ 'action' in props ? `The application will restart in ${countdown} seconds.` : ""}</div>
                <div className={styles.button_container}>
                    { 'action' in props ? <Button data-testid="button-tag" text={'Restart now'} action={props.action}/> : null }
                </div>
        </div>
    </div>);
};

export default Error;