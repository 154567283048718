import React, { useContext } from 'react';
import {Link} from "react-router-dom";
import './listitem.css';
import Image from "../../../Framework/Image/image";
import Button from "../../../Button/button";
import {getTranslation, getUrlPath} from "../../../../common/functions";
import { Store } from '../../../../Store';

const ListItem = props => {
    const { state } = useContext(Store);
    const sizes = "50vw";

    return(
        <Link to={getUrlPath(props.type) + '/' + props.id} className="article-item">
            <Image key={props.index} alt={props.alt} src={props.img} sizes={sizes}/>
            <div className="article-item-text">
                { props.credit ?
                    <div className="article-item-text-credit">
                        by <b>{props.credit}</b>
                    </div> : null
                }
                <div className="article-item-text-desc">{props.desc}</div>
                <div>
                    <Button text={getTranslation(state.localeSettings.locale, 'readMore')}/>
                </div>
            </div>
        </Link>
    );
};

export default ListItem;