import React, {useState, useEffect, useContext, useCallback, useRef, useMemo} from 'react';
import styles from './slideshow.module.css';
import { Store } from '../../../Store';
import {getPres, parseMediaFromRecord} from "../../../common/functions";
import HeaderRow from "../../Framework/HeaderRow/headerrow";
import history from "../../../history";
import {AUTOPLAY_IMAGE_TIMER} from "../../../common/settings";
import Menu from "../../Framework/Menu/menu";

const Slideshow = props => {
    const { state, dispatch } = useContext(Store);
    const [ presentation, setPresentation ] = useState({});
    const [ idx, setIdx ] = useState(0);
    const [ asyncIds, setAsyncIds ] = useState([]);
    const clearTimeoutRef = useRef(() => {});

    const disabledMenu = useMemo(() => {
        try{
            return state.kiosk.presentations.length < 2 ? true : false;
        } catch(err){
            return false
        }
    }, [state])



    const resetInactiveTimeout = () => {
        dispatch({
            type: 'SET_INACTIVE_PAUSED',
            payload: new Date().getSeconds()
        });
    };

    const appendTimeout = (id) => {
        setAsyncIds( (prev) => {
            return [...prev, id];
        })
    }

    const removeTimeout = (id) => {
        setAsyncIds( (prev) => {
            return [...prev.filter( _id => _id !== id )];
        })
    }

    const clearTimeouts = useCallback( () => {
        asyncIds.forEach( (id) => {
            clearTimeout(id);
            removeTimeout(id)
        })
        asyncIds && setAsyncIds([]);
    }, [asyncIds])

    clearTimeoutRef.current = clearTimeouts;

    const nextSlide = () => {
        resetInactiveTimeout();
        const LENGTH = presentation.elements.length;
        setIdx((idx + 1) % LENGTH);
    };

    const startImgTimer = () => {
        let timeout = setTimeout( () => {
            nextSlide();
            removeTimeout(timeout);
        }, AUTOPLAY_IMAGE_TIMER);
        appendTimeout(timeout);
    };

    const isValidMedia = (idx, elements) => {
        try {
            return 'type' in elements[idx];
        } catch (err){
            console.log(`Could not access media on elements, idx`, elements, idx);
        }
        return false
    }

    useEffect( () => {
        if ('presentations' in state.kiosk){
            clearTimeoutRef.current();
            let pres = getPres(state.kiosk.presentations, history.location.pathname);
            pres.records = 'records' in state.kiosk ? state.kiosk.records : [];
            const childIds = pres.children.map(c => c.record_id);
            let recsOfPres = pres.records.filter(r => childIds.includes(r.id));
            pres.elements = recsOfPres.flatMap( x => parseMediaFromRecord(x, "", state.localeSettings.locale));
            pres.elements = pres.elements.filter( x => x.type === 'image' || x.type === 'video');
            pres.elements.forEach( x => {
                try {
                    x.src = x.url[0].url;
                } catch (err){
                    console.log("cant get image of element. Err: ", err);
                }
            })
            setIdx(0);
            setPresentation(pres);
        }
    }, [state.kiosk, state.localeSettings.locale]);

    return(
        <div className={styles.container}>
            <div className="page">
                { state.localeSettings.locales.length > 1 ?
                    <HeaderRow {...(disabledMenu ? {menuDisabled: true} : {})}
                               languageSwitcher
                               filter={true}
                               className="article-header-row"
                               title=""
                               toggleMenu={props.toggleMenu}
                    /> : null }
                <div>
            { typeof idx === 'number' && 'elements' in presentation && isValidMedia(idx, presentation.elements) ?
                <div>
                    { presentation.elements[idx].type === 'video' ?
                    <video
                        autoPlay
                        onEnded={nextSlide}
                        onTimeUpdate={() => {resetInactiveTimeout();}}
                        loop={presentation.elements.length === 1}
                        src={window.wrapUrl(presentation.elements[idx].src)}
                        className={styles.item}
                        data-testid="video-tag"
                    />
                    :
                    <img
                        onLoad={startImgTimer}
                        src={window.wrapUrl(presentation.elements[idx].src)}
                        className={styles.item}
                        alt=""
                        data-testid="img-tag"
                    />
                }
                </div>
                : null}
                </div>
            </div>
            {props.isMenuOpen ? <Menu toggleMenu={props.toggleMenu}/> : null}
        </div>
    );
};

export default Slideshow;