import React, { useState, useEffect, useContext, Suspense } from 'react';
import HeaderRow from "../../Framework/HeaderRow/headerrow";
import Menu from "../../Framework/Menu/menu";
import { Store } from "../../../Store"
import history from "../../../history";
import {getPres, getTypeName, parseMediaFromRecord} from "../../../common/functions";
import Loader from "../../Loader/loader";
import styles from './photogallery.module.css';

const Gallery = React.lazy( () => import('../../Gallery/gallery'));

const PhotoGallery = props => {
    const { state } = useContext(Store);
    const [ presentation, setPresentation ] = useState({});
    const [ kioskRecords, setKioskRecords ] = useState([]);
    const [ view, setView ] = useState({});

    useEffect( () => {
       let pres = presentation;
       let loc = state.localeSettings.locale;
       let _view = {};
       _view.title = 'contents' in pres ? pres.contents[loc].title : getTypeName(pres.type, loc);
       let records ='children' in pres ?  pres.children.map( x => kioskRecords.find( y => x['record_id'] === y.id )) : [];
       let images = [];
       records.forEach( rec => {
           images = images.concat(parseMediaFromRecord(rec, 'image', loc));
       });
       let formattedImages = [];
       images.forEach( image => {
          let img = {};
          let content = 'contents' in image && loc in image.contents ? image.contents[loc] : false;
          img.title = content && 'title' in content ? content.title : '';
          img.desc = content && 'description' in content ? content.description : '';
          img.image = 'url' in image ? image.url[0].url  : '';
          img.type = 'image';
          img.credits = image.credits;
          if ('image' in img) formattedImages.push(img);
       });
       _view.elements = formattedImages;
       setView(_view);
    }, [presentation, state.localeSettings.locale, kioskRecords]);

    useEffect( () => {
        if ('presentations' in state.kiosk){
            let pres = getPres(state.kiosk.presentations, history.location.pathname);
            setPresentation(pres);
            setKioskRecords('records' in state.kiosk ? state.kiosk.records : []);
        }
    }, [state.kiosk]);

    useEffect( () => {

    }, []);

    return(
        <div className="page">
            <HeaderRow class={"article-header-row"} title={view.title} toggleMenu={props.toggleMenu}/>
            <Suspense fallback={<div className={styles.loader_container}><Loader/></div>}>
                { view.elements ? <Gallery items={view.elements}/> : null}
            </Suspense>
            { props.isMenuOpen ? <Menu toggleMenu={props.toggleMenu}/> : null }
        </div>
    );
};

export default PhotoGallery;