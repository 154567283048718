import React, { useState, useEffect, useContext } from 'react';
import { Store } from '../../../Store.js';
import ListItem from "./ListItem/listItem";
import Menu from "../../Framework/Menu/menu";
import HeaderRow from "../../Framework/HeaderRow/headerrow";
import './list.module.css';
import history from "../../../history";
import styles from './list.module.css';
import {
    getCreditOfRecord,
    getPres,
    getTypeName,
} from "../../../common/functions";

const List = props => {
    const { state } = useContext(Store);
    const [ presentation, setPresentation ] = useState({});
    const [ records, setRecords ] = useState([]);
    const [ items, setItems ] = useState([]);
    const [ view, setView ] = useState({
        title: ""
    });


    useEffect( () => {
        if('presentations' in state.kiosk){
            let pres = getPres(state.kiosk.presentations, history.location.pathname);
            if (pres){
                setPresentation(pres);
            }
            setRecords('records' in state.kiosk ? state.kiosk.records : []);
        }
    }, [state.kiosk]);

    useEffect( () => {
        let pres = presentation;
        let loc = state.localeSettings.locale;
        if ('contents' in pres){
            let title = pres.contents[loc].title || getTypeName('index', loc);
            setView({title});
        }
        let presItems = ('children' in pres) ? pres.children : [];
        presItems.forEach( y => y.record = records.find( x => (x.id === y['record_id'])));
        // filter by selected locale
        presItems = presItems.filter(x => loc in x.record.contents);
        presItems.forEach( item => {
            item.title = loc in item.record.contents ? item.record.contents[loc].title : '';
            let credit = getCreditOfRecord(item.record);
            item.credit = (credit && credit.length > 0 && 'name' in credit[0]) ? credit[0].name : false;
        });
        setItems([...presItems]);
    }, [presentation, records, state.localeSettings.locale]);


    useEffect( () => {

    }, []);


    return(
        <div className="page">
            <HeaderRow toggleMenu={props.toggleMenu} title={view.title}/>
            <div className={styles.container}>
                { items ?
                    items.map( (item, i) => <ListItem key={item['u_id']} index={i} id={item['record_id']} img={item.image} desc={item.title} credit={item.credit || false}/>)
                    : <div>No presentations found with locale {state.localeSettings.locale} in presentation.</div>}
            </div>
            {props.isMenuOpen ? <Menu toggleMenu={props.toggleMenu}/> : null}
        </div>
        );
};

export default List;