import React, { useState, useContext } from 'react';
import Image from '../Framework/Image/image';
import { ReactComponent as VideoIcon } from './video2.svg';
import { ReactComponent as AudioIcon } from './audio.svg';
import { ReactComponent as ImageIcon } from './image.svg';
import ImageView from "../Views/ImageView/imageview";
import { Store } from "../../Store";
import NoContent from "../NoContent/nocontent";
import styled from 'styled-components';



const genGridTemplateByElements = elements => {
    switch (elements) {
        case 1:
            return "repeat(1, 100%) / repeat(1, 1fr)";
        case 2:
            return "repeat(1, 100%) / repeat(2, 1fr)";
        case 3:
            return "repeat(1, 100%) / repeat(3, 1fr)";
        case 4:
            return "repeat(2, calc(100% / 2)) / repeat(2, 1fr)";
        default:
            return "repeat(2, calc(100% / 2)) / repeat(500, calc(100% / 3))";

    }
}

const Container = styled.div`
    flex: 1;
    margin-top: 64px;
    display: grid;
    grid-gap: 12px;
    grid-template: ${((props) => 'length' in props && genGridTemplateByElements(props.length))};
    grid-auto-flow: column;
    padding-bottom: 72px;
    color: hsl(345, 47.1%, 93.3%);
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      background-color: rgba(255,255,255,0);
      height: calc(calc(var(--scrollbar-padding) * 2) + var(--scrollbar-height))
    }
    ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
      border-top: 16px solid rgba(255,255,255,0);
      border-bottom: 16px solid rgba(255,255,255,0);
      background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
       background-color: var(--theme-bgColor2);
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--theme-textColor2);
    }
    ::-webkit-scrollbar-thumb:hover {
      border: var(--scrollbar-padding) solid rgba(255,255,255,0);
    }
`;

const Item = styled.div`
    position: relative;
    display: flex;
    :nth-child(6n+1) {
      scroll-snap-align: start;
    }
    :active {
      filter: brightness(0.6);
    }
    :after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        content: "";
        background: linear-gradient(to bottom, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const IconContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    /*margin-top: 30px;
    margin-right: 30px;
    border-radius: 30px;
    padding: 30px;*/
    margin-top: calc(30rem / 29);
    margin-right: calc(30rem / 29);
    padding: calc(30rem / 29);
    border-radius: 50%;
    background-color: var(--theme-detailColor);
    svg {
        position: absolute;
        width: 40%;
        height: 40%;
        transform: translate(-50%, -50%);
    }
`;

const NoContentContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const Gallery = props => {
    const { state, dispatch } = useContext(Store);
    const [startIndex, setStartIndex] = useState(null);
    const sizes = "50vw";

    const checkLoaded = (e) => {
        if (!props.isLoading){
            return;
        }
        const _GALLERY = e.target.closest('.gallery');
        if (_GALLERY){
            let images = _GALLERY.getElementsByTagName('IMG');
            let counter = 0;
            Array.from(images).forEach( (item, i) => {
                if (item.complete && typeof item.naturalWidth === 'number'){
                    counter++;
                }
            });
            if (counter >= images.length){
                console.log("settings is loading false");
                props.setIsLoading(false);
            }
        }
    };

    const toggleFullscreen = (e) => {
        e.preventDefault();
        let idx = e.currentTarget.getAttribute("id");
        idx = parseInt(idx);
        setStartIndex(idx);
        return dispatch({
            type: 'MEDIA_FULLSCREEN',
            payload: true
        });
    };

    const textStyles = {
        top: "unset",
        right: "unset",
        bottom: "0",
        left: "0"
    };

    const getIcon = (type) => {
        switch (type){
            case 'video':
                return <VideoIcon/>;
            case 'image':
                return <ImageIcon/>;
            case 'audio':
                return <AudioIcon/>;
            default:
                return <ImageIcon/>;
        }
    };

    if (props.isLoading && props.items.length === 0){
        props.setIsLoading(false);
    }

    return(
            <Container length={props.items ? props.items.length : 6} className={'gallery'}>
                { 'items' in props && props.items.length > 0 ? props.items.map( (item, i) => {
                    return(
                        <Item onClick={toggleFullscreen} id={i} key={i} data-testid="gallery-item-tag">
                            <Image onLoad={checkLoaded} src={item.image} title={item.title} textStyles={textStyles} sizes={sizes} key={i}/>
                            <IconContainer>
                                { 'type' in item ? getIcon(item.type) : null }
                            </IconContainer>
                        </Item>
                    );
                }) :  <NoContentContainer><NoContent/></NoContentContainer>  }
                { 'isMediaFullscreen' in state && state.isMediaFullscreen ? <ImageView startIndex={startIndex} items={props.items}/> : null }
            </Container>
    );

};

export default Gallery;