import React, { useContext } from 'react';
import { ReactComponent as Icon } from "./baseline-clear-24px.svg";
import LanguageItem from '../LanguageItem/languageitem.js';
import { Store } from "../../../../Store";
import './languageoverlay.css';

function LanguageOverlay(props) {
    const { state, dispatch } = useContext(Store);

    const toggleLanguage = (e) => {
        const chosenLang = e.target.closest('.language-item').getAttribute('lang');
        const { localeSettings: settings } =  state;
        settings.locale = chosenLang;
        dispatch({
            type: 'SET_LOCALE_SETTINGS',
            payload: settings
        });
        props.changeLang();
    };

    return(<div className="language-overlay-bg-filter">
        <div className="language-overlay-content">
        <div className="language-overlay-header">
            Select Language
        </div>
        <div onClick={props.changeLang}>
            <Icon className="language-close-button"/>
        </div>
        <div className="language-spacer"/>
        <div className="language-flag-container">
            { 'kiosk' in state && state.localeSettings.locales.map( (item, i) => {
                return(<LanguageItem selectedLang={state.localeSettings.locale} toggleLanguage={toggleLanguage} key={i} lang={item}/>);
            })}
        </div>
    </div>
    </div>);
}

export default LanguageOverlay;