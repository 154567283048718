import React, { useState, useEffect, useContext } from 'react';
import { ReactComponent as PlayIcon } from "./baseline-play_arrow-24px.svg";
import { ReactComponent as PauseIcon } from "./baseline-pause-24px.svg";
import { ReactComponent as ClockIcon } from "./clock.svg";
import { formatDuration } from "../../../../common/functions";
import './audioplayer.css';
import { Store } from '../../../../Store';
import ProgressBar from "./ProgressBar/progressbar";

const AudioPlayer = (props) => {
    const { state, dispatch } = useContext(Store);
    const [isPlaying, setIsPlaying] = useState(false);
    const [ player, setPlayer ] = useState(null);
    const [ metaData, setMetaData] = useState({
        currentTime: "00:00"
    });

    const togglePlayer = () => {
        if (player.paused){
            player.play();
            setIsPlaying(true);
            dispatch({
                type: 'SET_CURRENT_MEDIA_ID',
                payload: player.src
            });
        } else {
            player.pause();
            setIsPlaying(false);
        }
    };

    const changeProgress = (e) => {
        const src = e.nativeEvent.target;
        const cont = src.classList.value.includes('fill') ? src.parentNode : src;
        let percentage = e.nativeEvent.offsetX / cont.clientWidth;
        player.currentTime =  parseFloat((percentage * player.duration).toFixed(6));
    };

    useEffect( () => {
            let audio = document.createElement('audio');
            audio.src = window.wrapUrl(props.src);
            setPlayer(audio);
            return () => {
                audio = null;
            };
    },[props.src]);


    useEffect( () => {

        if (!state.currentMediaId || !player){
            return;
        }
      if (state.currentMediaId !== player.src){
          player.pause();
          setIsPlaying(false);
      }
    }, [state.currentMediaId, player]);


    useEffect( () => {

        const timeupdateHandler = () => {
            if (!player){
                return;
            }
            setMetaData(prevData => {
                return { ...prevData,
                    durFormat: formatDuration(player.duration),
                    curTimeFormat: formatDuration(player.currentTime),
                    duration: player.duration,
                    currentTime: player.currentTime.toString()
                };
            });
            dispatch({
                type: 'SET_INACTIVE_PAUSED',
                payload: player.currentTime
            });
        };

        const endedHandler = () => {
            if (!player){
                return;
            }
            player.currentTime = 0;
            setIsPlaying(false);
        };

        if(player && 'src' in player){
            player.onloadedmetadata = () => {
                setMetaData( prevData => {
                    return {
                        ...prevData,
                        duration: player.duration,
                        durFormat: formatDuration(player.duration)
                    };
                });
            };
            player.addEventListener('ended', endedHandler);
            player.addEventListener('timeupdate', timeupdateHandler);
            return () => {
                player.removeEventListener('timeupdate', timeupdateHandler);
                player.removeEventListener('ended', endedHandler);
                player.onloadedmetadata = null;
                player.pause();
                setPlayer(null);
            };
        }
    }, [player, dispatch]);


    return(
        <div className="audioplayer">
            { 'item' in props ? props.item : null }
            <div className="audioplayer-content-container">
                <div className="audioplayer-content-title">{ props.title }</div>
                <div className="audioplayer-content-titledesc">
                    <ClockIcon/><p>Total: { metaData.durFormat }</p>
                </div>
                <div className="audioplayer-content-interface">
                    <div className="audioplayer-content-play">
                        {(isPlaying) ? <PauseIcon onClick={togglePlayer} fill="#FFF"/> : <PlayIcon onClick={togglePlayer} fill="#FFF"/>}
                    </div>
                    {/* IF WANTED
                    <div onClick={changeProgressPlus}>+10</div>
                    <div onClick={changeProgressMinus}>-10</div>
                    */}
                    <div className="audioplayer-content-timeline">
                        <div className="audioplayer-content-starttime">{ metaData.curTimeFormat || "00:00" }</div>
                        <ProgressBar changeProgress={changeProgress} percentage={metaData.currentTime * 100 / metaData.duration}/>
                        <div className="audioplayer-content-endtime">{ metaData.durFormat }</div>
                    </div>
                </div>
                <div className="audioplayer-spacer"/>
                <div className="audioplayer-content-desc"> { props.desc } </div>
            </div>
        </div>
    );
};

export default AudioPlayer;