import React, { useRef } from 'react';
import styles from './slider.module.css';
import Image from "../../../Framework/Image/image";
import Swipe, { SwipeItem } from 'swipejs/react';
import Video from "../../../Framework/Video/video";

const Slider = (props) => {
   const swipeEl = useRef(null);
   const sizes = "100vw";

    return(
            <div className={styles.container} data-testid="swipe-parent-tag">
                <Swipe auto={0} startSlide={props.startSlide || 0} callback={props.toggleInfoIndex} className={styles.swipe} ref={swipeEl}
                       >
                    { 'items' in props ? props.items.map( ( item, i ) => {
                        return(
                            <SwipeItem className={styles.swipe_wrap_item} key={item.image}>
                                { item.type === 'image' || item.type === 'record' ?  <Image src={item.image} sizes={sizes}/>  : null}
                                { item.type === 'video' ?  <Video autoplay={props.startSlide ? props.startSlide === i : i === 0} src={ item.video } overlay />  : null}
                            </SwipeItem>
                        );
                    }) : null}
                </Swipe>
            </div>
    );
};

export default Slider;