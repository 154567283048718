import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Enzyme from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';
import { StoreProvider } from './Store.js';
import * as serviceWorker from './serviceWorker';
import Device from "./components/Views/Device/device";

Enzyme.configure({ adapter: new Adapter() });

window.wrapUrl = function(url){
    return url;
};


ReactDOM.render(<StoreProvider><Device/></StoreProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
