import React from 'react';
import styles from './pagenav.module.css';
import PageNavItem from "./PageNavItem/pagenavitem";

const PageNav = props => {
    return(
        <div className={styles.container}>
            { props.items ? props.items.map( (item, i) => {
                return(<div className={styles.item} key={i}><PageNavItem action={props.action} index={item.position} selected={item.selected} text={item.title}/></div>);
            }) : null}
        </div>
    );
}

export default PageNav;