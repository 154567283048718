import React, { useEffect, useState, useContext } from 'react';
import styles from './imagemap.module.css';
import HeaderRow from "../../Framework/HeaderRow/headerrow";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Menu from "../../Framework/Menu/menu";
import { Store } from '../../../Store';
import {checkNested, fallbackArray, getImage, getPres, getVarWFallback} from "../../../common/functions";
import Label from "./Label/label";
import history from "../../../history";
import LineLabel from "./LineLabel/linelabel";

const ImageMap = props => {
    const { state } = useContext(Store);
    const [ presentation, setPresentation ] = useState({});
    const [ title, setTitle ] = useState("");
    const [ labels, setLabels ] = useState([]);
    const [ map, setMap ] = useState({});
    const [ labelScale, setLabelScale ] = useState(1.0);
    const [ imageDetails, setImageDetails ] = useState({});
    const [ imageStyles, setImageStyles ] = useState({});

    const toggleInfoBox = id => {
        let tmpLabels = labels;
        tmpLabels.forEach( label => {
           if (label.record_id === id && !label.isActive){
              label.isActive = true;
           } else {
               label.isActive = false;
           }
        })
        setLabels([...tmpLabels])
    };

    const hideInfoBoxes = (e = null) => {
        const isLabel = e ? e.nativeEvent.target.classList.value.includes('label') : false;
        if (isLabel){
            return;
        }
        let tmpLabels = labels;
        tmpLabels.forEach( x => {
            x.isActive = false;
        })
        setLabels([...tmpLabels]);
    };

    const calibrateLabels = e => {
        setLabelScale(1/e.scale);
        //hideInfoBoxes();
    };

    const imageLoadHandler = e => {
       let img = e.nativeEvent.target;
       if (e.type === 'load' && 'naturalWidth' in img){
           console.log(img.naturalWidth, img.naturalHeight);
           setImageDetails({height: img.naturalHeight, width: img.naturalWidth});
       }
    };


    useEffect( () => {
        if (!presentation){
            return;
        }
        try {
            let children = presentation.children;
            children.forEach( item => {
                let rec = state.kiosk.records.find(x => x.id === item['record_id']);
                let _title = getVarWFallback(rec.contents, 'title');
                if (_title) item.title = _title;
                let _desc = getVarWFallback(rec.contents, 'description');
                if (_desc) item.desc = _desc;
                let _img = getImage(rec.image);
                if (_img) item.image = _img;
                item.isActive = false;
                let isTimeline = !!('map_image' in item && 'anchorOn' in item.map_image && item.map_image.anchorOn);
                item.type = "single";
                if (isTimeline){
                    item.type = "anchor";
                    const [ lang ] = fallbackArray();
                    if (checkNested(item, 'text', lang, 'title')) item.anchorTitleData = item.text[lang].title;
                    if (checkNested(item, 'text', lang, 'description')) item.anchorDescData = item.text[lang].description;
                }
                return item;
            });
            setLabels(children);
            let mapUrl = presentation.resources[0].urls[0].url;
            mapUrl = mapUrl.includes('?') ? mapUrl.split('?')[0] + "?dimension=max" : mapUrl + '?dimension=max';
            setMap( prevMap => { return {...prevMap, url: mapUrl}; });
            let articleTitle = presentation.contents[state.localeSettings.locale].title;
            setTitle(articleTitle);
        } catch (err) {

        }
    }, [presentation, state.kiosk.records, state.localeSettings.locale]);

    useEffect( () => {
        if (!(state.kiosk && 'presentations' in state.kiosk)){
            return;
        }
        console.log(history.location.pathname);
        let pres = getPres(state.kiosk.presentations, history.location.pathname);
        if (pres){
            setPresentation({...pres});
        }
    }, [state.kiosk])

    useEffect( () => {

        const calibrateImage = () =>  {
            let details = {...imageDetails};
            if (Object.entries(details).length === 0){
                return;
            }
            //END DEBUG
            if (details.height / details.width >= document.documentElement.clientHeight/ document.documentElement.clientWidth){
                setImageStyles({
                    height: 'auto',
                    width: document.documentElement.clientWidth + 'px'
                });
            } else {
                setImageStyles({
                    height: document.documentElement.clientHeight + "px",
                    width: 'auto'
                });
            }
        };

        calibrateImage();
        window.onresize = () => {
            calibrateImage();
        };
        return () => {
            window.onresize = null;
        };

    }, [imageDetails])


    useEffect( () => {

    }, []);


    return(
        <div>
            <TransformWrapper onPinching={calibrateLabels} eneablePanPadding={false} options={{centerContent: false, limitToBounds: false}}>
                <div className={styles.header_container}>
                    <HeaderRow fixed filter title={title} toggleMenu={props.toggleMenu} />
                </div>
                <TransformComponent>
                    <div onClick={hideInfoBoxes} data-testid="filter-tag">
                        <img onLoad={imageLoadHandler} style={imageStyles} src={window.wrapUrl(map.url)} data-testid="imagemap-tag" alt="Map with labels"/>
                        <div className={styles.labels_container}>
                            { labels && labels.map( (label, i) => {
                               return( label.type === 'anchor' ?
                                   <LineLabel label={label} map={imageDetails} key={label.u_id}/> :
                                   <Label toggleInfoBox={toggleInfoBox} scale={labelScale} key={label.u_id} label={label} map={imageDetails}/>);
                            })}
                        </div>
                    </div>
                </TransformComponent>
                { props.isMenuOpen ? <Menu toggleMenu={props.toggleMenu}/> : null }
            </TransformWrapper>
        </div>
    )
};

export default ImageMap;