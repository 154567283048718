import React, { useState, useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import styles from './block.module.css';
import Image from "../../../../Framework/Image/image";
import {extractElementData, getVarWFallback, getPosterUrl} from "../../../../../common/functions";
import Pagination from "./Pagination/pagination";
import { Store } from "../../../../../Store";
import AudioPlayer from "../../AudioPlayer/audioplayer";
import BlockImage from "./Media/block_image";
import Block_image_video from "./Media/block_image_video";

const Block = (props) => {
    const { state, dispatch } = useContext(Store);
    const [ galleryItems, setGalleryItems ] = useState([]);
    const [ blockData, setBlockData ] = useState({});

    const customStyles = {
        topPaddingClip: {
            paddingTop: '15px'
        },
        altBlockBgColor: {
            background: 'var(--theme--bgColor-altBlock)'
        }
    };

   const showText = (block) => {
       if(block && 'elements' in block && typeof block.elements == 'string'){
           let text = block.elements;
           text = text.replace(/<p>(<br>)?<\/p>/ig, '');
           return <div className={styles.item_text}><ReactMarkdown source={text} escapeHtml={false}/></div>;
       }
   };

    const setCurrentGallery = (e) => {
        e.preventDefault();
        if (e.target.classList.value.includes('caption')){
            return;
        }
        let idx = e.target.closest('.elements > div').getAttribute('id');
        idx = parseInt(idx);
        dispatch({
            type: 'CURRENT_GALLERY_START_ID',
            payload: idx
        });
        dispatch({
            type: 'CURRENT_GALLERY',
            payload: galleryItems
            });
        dispatch({
            type: 'MEDIA_FULLSCREEN',
            payload: true
        });
    };

    const showImageVideo = (block) => {
        const loc = state.localeSettings.locale;
        if (block && 'elements' in block &&
        Array.isArray(block.elements) && block.elements.length > 0){
           const IS_SOLO = block.elements.length === 1;
           return (<div className={styles.container_image + " elements " + (IS_SOLO ? styles.container_image_solo : '')}>{block.elements.map( (item, i) => {
                if (!item){
                    return null;
                }
                if(item.type === 'video'){
                    return <Block_image_video item={item} idx={i} block={block} loc={loc} setCurrentGalleryFn={setCurrentGallery}/>
                } else if (item.type === 'image'){
                    return <BlockImage item={item} block={block} loc={loc} idx={i} setCurrentGalleryFn={setCurrentGallery}/>

                } else if (item.type === 'vimeo'){
                    return null;
                }
                return item;
            })}</div>);

       }

    };

    const showAudio = (block) => {
       if (block && 'elements' in block){
           return (block.elements.map( (item, i) => {
               let title = getVarWFallback(item.contents, 'title');
               let desc = getVarWFallback(item.contents, 'desc');

               return(<div key={i} className={styles.item_audio}><AudioPlayer title={title || 'Lydspiller'} desc={desc || false} src={item.url[0].url}/></div>);
           }));
       }
    };

    const getMultiStyles = () => {
      let _styles = {};
      if (props.odd)
          _styles = {..._styles, ...customStyles.altBlockBgColor };
      if (!props.isImageSet && props.index === 0){
          _styles = { ..._styles, ...customStyles.topPaddingClip };
      }
      return _styles;
    };

    useEffect( () => {
        if (props.block){
            setBlockData({
                type: props.block.type,
                position: props.block.position,
                id: props.block.id,
                elements: ('data' in props.block) ? props.block.data : false
            });
            if (props.block.type === 'image_video' &&
                Array.isArray(props.block.data)){
                let items = [];
                props.block.data.map( (item) => {
                    let tmp = extractElementData(item);
                    if (tmp){
                        items.push(tmp);
                    }
                    return item;
                });
                setGalleryItems(items);
            }
        }

    }, [ props.block ]);

    return(
        <div className={styles.container} style={getMultiStyles()}>
            { blockData && blockData.type === 'text' ?
                showText(blockData) : null
            }

            { blockData && blockData.type === 'image_video' ?
                showImageVideo(blockData) : null
            }

            { blockData && blockData.type === 'audio' ?
                showAudio(blockData) : null
            }

        </div>

    );
};

export default Block;