import React from 'react';
import ErrorBoundaryContent from "./components/Views/ErrorBoundaryContent/errorboundarycontent";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null};
    }

    static getDerivedStateFromError(error){
        return { hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        this.setState({...this.state,
            error: error,
            errorInfo: errorInfo
        });
    }

    render(){
        if (this.state.hasError) {
            return (
                <ErrorBoundaryContent
                    text={`Something went wrong. Error: ${this.state.error}.`}/>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;