import React from 'react';
import styles from './page.module.css';
import Block from './Block/block.js';

const Page = props => {
    const hideTitle = !!('hide_title' in props && props.hide_title);

    return(
        <div>
            { props.page.title ? <h2 className={styles.page_title} data-testid="pagetitle-tag">{ props.page.title && hideTitle === false ? props.page.title : null }</h2> : null }
            { props.page.description ? <p>{ props.page.description }</p> : null }
            { 'blocks' in props.page ?
                props.page.blocks.map( (block, i) => {
                    return(<Block block={block} key={i} isImageSet={props.isImageSet} index={i} odd={i % 2 === 1 ? true : false}/>);
                })
                : null }
        </div>
    );
};

export default Page;