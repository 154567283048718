import React, { useState, useEffect } from 'react';
import { ReactComponent as PlayIcon } from './play_dfs.svg';
import Caption from "../../Caption/caption";
import styles from './image.module.css';
import { getImageByDim, getImageSrcSet } from "../../../common/functions";

const Image = props => {
    const [ src, setSrc ] = useState('');
    const [ srcSet, setSrcSet ] = useState('');

    const onLoadHandler = (e) => {
        if (props.onLoad){
            props.onLoad(e);
        }
        initSrcSet();
    };

    const initSrcSet = () => {
        const _getImageSrcSet = (src)=>props.noMaxSrcSet ? getImageSrcSet(src, true):getImageSrcSet(src)
        const _src = !!window.wrapUrl ? window.wrapUrl(_getImageSrcSet(src)) : _getImageSrcSet(src);
        setSrcSet(_getImageSrcSet(_src));
    };

    useEffect( () => {
        if (props.src){
            if (!!window.wrapUrl){
                setSrc(window.wrapUrl(getImageByDim(props.src, '167x167')));
            } else {
                setSrc(getImageByDim(props.src, '167x167'));
            }
        }
    }, [props.src]);

    return(
        <div className={styles.container}>
            <div className={styles.image_wrapper}>
                { src ?
                    <img onLoad={onLoadHandler}
                         className={styles.image + ` ${ srcSet ? 'fadeInImage' : ''}`}
                         data-testid="img-tag"
                         src={src}
                         srcSet={srcSet}
                         sizes={props.sizes}
                         alt={props.alt}
                         style={props.imageStyles}
                         loading={props.lazy ? "lazy" : ''}/>
                    :
        null
                }
                <div className={styles.text} style={props.textStyles}>
                    { props.title ? <div className={styles.title} style={props.titleStyles}>{props.title}</div> : null}
                    { props.desc ? <div className={styles.desc} style={props.descStyles}>{props.desc}</div> : null}
                </div>
                { props.playFilter ? <div className={styles.play_filter}>
                    <div className={styles.play_filter_overlay}>
                        <div className={styles.play_text_container}>
                            <div className={styles.play_title}>{ /* props.playFilter.title */}</div>
                            <div className={styles.play_desc}>{ /* props.playFilter.desc */}</div>
                        </div>
                        <div className={styles.play_icon}>
                            <PlayIcon/>
                        </div>
                    </div>
                </div> : null }
            </div>
            { props.caption ?
                <Caption
                title={'title' in props.caption ? props.caption.title : ''}
                desc={'desc' in props.caption ? props.caption.desc : ''}
                credits={'credits' in props.caption ? props.caption.credits : ''}
            /> : false }
        </div>
    );

};

export default Image;