import React, { useContext } from 'react';
import styles from './credit.module.css';
import {getTranslatedCreditType} from "../../common/functions";
import {Store} from "../../Store";

const Credit = props => {
    const { state } = useContext(Store);

    const cStyles = {
        background: 'bg' in props && props.bg ? 'var(--theme-detailColor)' : 'unset',
        filter: 'bg' in props && props.bg ? 'unset' : 'drop-shadow(0px 0px 50px white)'
    };

    return (
        <div className={styles.container} style={cStyles} data-testid="credit-tag">
            {props.credits ? props.credits.map(item => {
                return(<div className={styles.item} key={`${item.type}-${item.name}`}>
                    <div className={styles.type}>{'type' in item ? getTranslatedCreditType(item.type, state.localeSettings.locale) : ''}</div>
                    <div className={styles.name}>{'name' in item ? item.name : ''}</div>
                </div>);
            })
                : null}
        </div>
    );
};

export default Credit;